export let exportMenuMerchendiser = [
	{
		icon: "uil-home",
		active: false,
		children: [
			{
				url: "/documents-in",
				i18n: "GOOD_RECEPTION",
				icon: "uil-bring-front",
				active: false,
			},
			{
				url: "/nomenclature",
				i18n: "NOMENCLATURE",
				icon: "uil-list-ul",
				active: false,
			},
			{
				url: "/documents-out",
				i18n: "RETURN_OF_GOODS",
				icon: "uil-backspace",
				active: false,
			},
			{
				url: "/scale",
				i18n: "SCALE",
				icon: "uil-balance-scale",
				active: false,
			},
			{
				url: "/grouping",
				i18n: "GROUPING",
				icon: "uil-object-ungroup",
				active: false,
			},
			{
				url: "/transfer",
				i18n: "DISPLACEMENT",
				icon: "uil-truck",
				active: false,
			},
			{
				url: "/inventory",
				i18n: "INVENTORY",
				icon: "uil-calculator-alt",
				active: false,
			},
			{
				url: "/notifications",
				i18n: "NOTIFICATIONS",
				icon: "uil-bell",
				active: false,
			},
			{
				url: "/price-tags",
				i18n: "PRICE_TAGS",
				icon: "uil-pricetag-alt",
				active: false,
			},
			{
				url: "/reports",
				i18n: "REPORTS",
				icon: "uil-chart-pie-alt",
				active: false,
			}
		]
	}
]