import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'
import TableControl from 'pages/parts/TableControl'

import { PGET } from 'helpers/api'
import { formatMoney, formatDateWithTime } from 'helpers/helpers'

function Index() {
	const URL = "/services/web/api/price-history-pageList"
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)

	const [modalTableControl, setModalTableControl] = useState(false)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function getData() {
		const response = await PGET(URL)
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL, { page: data.selected, size: reduxSettings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('HISTORY')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									{(!reduxSettings.tableHistory || reduxSettings.tableHistory?.pos) &&
										<th>{t('POS')}</th>
									}
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th>{t('BARCODE')}</th>
									{(!reduxSettings.tableHistory || reduxSettings.tableHistory?.artikul) &&
										<th className="text-center">{t('ARTIKUL')}</th>
									}
									<th className="text-center">{t('ADMISSION_PRICE')}</th>
									<th className="text-center">{t('WHOLESALE_PRICE')}</th>
									{(!reduxSettings.tableHistory || reduxSettings.tableHistory?.bankPrice) &&
										<th className="text-center">{t('BANK_PRICE')}</th>
									}
									<th className="text-center">{t('UNIT_PRICE')}</th>
									{/* <th className="text-center">{t('LOGIN')}</th> */}
									<th className="text-center table-control">
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										{t('DATE_OF_CREATION')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										{(!reduxSettings.tableHistory || reduxSettings.tableHistory?.pos) &&
											<td>{item.posName}</td>
										}
										<td>{item.productName}</td>
										<td>{item.productBarcode}</td>
										{(!reduxSettings.tableHistory || reduxSettings.tableHistory?.artikul) &&
											<td>{item.productArtikul}</td>
										}
										<td>
											<div className="d-flex justify-content-center">
												<div className="me-2">{formatMoney(item.oldPrice)}</div>
												<div className="me-2">
													<i className="uil uil-arrow-right"></i>
												</div>
												{formatMoney(item.newPrice)}
											</div>
										</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="me-2">{formatMoney(item.oldWholesalePrice)}</div>
												<div className="me-2">
													<i className="uil uil-arrow-right"></i>
												</div>
												{formatMoney(item.newWholesalePrice)}
											</div>
										</td>
										{(!reduxSettings.tableHistory || reduxSettings.tableHistory?.bankPrice) &&
											<td>
												<div className="d-flex justify-content-center">
													<div className="me-2">{formatMoney(item.oldBankPrice)}</div>
													<div className="me-2">
														<i className="uil uil-arrow-right"></i>
													</div>
													{formatMoney(item.newBankPrice)}
												</div>
											</td>
										}
										<td>
											<div className="d-flex justify-content-center">
												<div className="me-2">{formatMoney(item.oldSalePrice)}</div>
												<div className="me-2">
													<i className="uil uil-arrow-right"></i>
												</div>
												{formatMoney(item.newSalePrice)}
											</div>
										</td>
										{/* <td className="text-center">
											{item.createdBy}
										</td> */}
										<td className="text-center">
											{formatDateWithTime(item.createdDate)}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableHistory'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Index
