import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select, { components } from 'react-select'
import XLSX from 'xlsx';

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { PGET, GET } from 'helpers/api'
import { formatMoney, returnSign, formatDate, formatDateBackend, findFromArrayById } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [poses, setPoses] = useState([])
	const [organizations, setOrganizations] = useState([])
	const [categories, setCategories] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [totalAmount, setTotalAmont] = useState(0)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [filterData, setFilterData] = useState({
		startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		endDate: formatDateBackend(new Date()),
		posId: null,
		productCategoryId: null,
		currencyId: 1,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/services/wms/api/report-gross-income/' + filterData.currencyId,
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = "/services/wms/api/report-gross-income/" + filterData.currencyId
		var urlParams = ""
		checkFilter()

		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.organizationId)
			urlParams += returnSign(urlParams) + 'organizationId=' + filterData.organizationId
		if (filterData.productCategoryId)
			urlParams += returnSign(urlParams) + 'productCategoryId=' + filterData.productCategoryId
		if (filterData.startDate)
			urlParams += returnSign(urlParams) + 'startDate=' + filterData.startDate
		if (filterData.endDate)
			urlParams += returnSign(urlParams) + 'endDate=' + filterData.endDate
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/wms/api/report-gross-income/" + filterData.currencyId + urlParams !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		if (tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = "/services/wms/api/report-gross-income-excel/" + filterData.currencyId + urlParams + returnSign(filterUrl)

			var excelHeaders = [[t('POS'), t('PRODUCT_ID'), t('NAME_OF_PRODUCT'), t('BARCODE'), t('NUMBER_OF_SALES'),
			t('AMOUNT_RECEIVED'), t('SALE_AMOUNT'), t('GROSS_INCOME')]]
			const response = await GET(filterUrl)
			for (let i = 0; i < response.length; i++) {
				var excelItems = []
				excelItems.push(response[i].posName)
				excelItems.push(response[i].productId)
				excelItems.push(response[i].productName)
				excelItems.push(response[i].productBarcode)
				excelItems.push(response[i].saleQuantity)
				excelItems.push(response[i].totalIncome)
				excelItems.push(response[i].totalSale)
				excelItems.push(response[i].grossIncome)
				excelHeaders.push(excelItems)
			}
			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('GROSS_INCOME') + ".xlsx");
		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			}

			filterUrl = "/services/wms/api/report-gross-income-total/" + filterData.currencyId + urlParams
			const temporaryTotalAmount = await GET(filterUrl)
			setTotalAmont(temporaryTotalAmount)
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			startDate: null,
			endDate: null,
			posId: null,
			productCategoryId: null,
			currencyId: 1,
			search: '',
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET("/services/wms/api/report-gross-income/" + filterData.currencyId, {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': "/services/wms/api/report-gross-income/" + filterData.currencyId, 'response': response })
		}
		const response2 = await GET("/services/wms/api/report-gross-income-total/" + filterData.currencyId, {}, { loader: false })
		if (response2) {
			setTotalAmont(response2)
		}
	}

	function checkFilter() {
		if (
			filterData.startDate === null &&
			filterData.endDate === null &&
			filterData.posId === null &&
			filterData.currencyId === 1 &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}


	async function getPoses() {
		const response = await GET('/services/wms/api/wms-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function getOrganizations() {
		const response = await GET('/services/web/api/organization-helper')
		if (response) {
			setOrganizations(response)
		}
	}

	async function getCategories() {
		const response = await GET('/services/web/api/product-category-helper')
		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ' ' + response[i]['name']
			response[i].child = false;
			if (response[i].parentId !== 0) {
				const index = response.findIndex(x => x.id === response[i].parentId)
				response[i].child = true;
				if (response[index].options && response[index].options.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}
		setCategories(arr)
		setCategoriesInitial(response)
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		getPoses()
		getCategories()
		getOrganizations()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: filterData.productCategoryId === props.data.id ? '#fff' : '#999',
				fontWeight: 'bold',
				backgroundColor: filterData.productCategoryId === props.data.id ? '#2684FF' : '#fff',
				fontSize: '.8rem'
			}}
				onClick={(e) => selectCategory(props.data)}>
				<components.Option {...props} />
			</div>
		);
	};

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
				fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	async function selectCategory(option) {
		setIsOpen(!isOpen)
		if (option === null) {
			getData()
			setFilterData({ ...filterData, 'productCategoryId': null })
			return
		}
		setFilterData({ ...filterData, 'productCategoryId': option.id })
	}

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('CURRENCY')}</label>
												<Select
													options={currencies}
													value={currencies.find(option => option.id === filterData.currencyId)}
													onChange={(option) => setFilterData({ ...filterData, 'currencyId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('CATEGORY')}</label>
												<Select
													isClearable
													components={{ GroupHeading: GroupHead, Option }}
													classNamePrefix="react-select"
													options={categories}
													value={categoriesInitial.find(option => option.id === filterData.productCategoryId) || ''}
													onChange={(option) => selectCategory(option)}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.label}
													getOptionValue={(option) => option.id}
													onMenuOpen={() => setIsOpen(true)}
													onBlur={() => setIsOpen(false)}
													menuIsOpen={isOpen}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('ORGANIZATION')}</label>
												<Select
													options={organizations}
													value={organizations.find(option => option.id === filterData.organizationId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'organizationId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('PERIOD')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.startDate ? new Date(filterData.startDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.endDate ? new Date(filterData.endDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
							</div>
						</div>
						<div className="vertical-center">
							<h4>
								<b>{t('TOTAL_AMOUNT')}</b>: {formatMoney(totalAmount)}
							</h4>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.organizationId &&
									<span className="me-2">
										<b>{t('ORGANIZATION')}: </b> {findFromArrayById(organizations, filterData.organizationId)}
									</span>
								}
								{filterData.productCategoryId &&
									<span className="me-2">
										<b>{t('CATEGORY')}: </b> {findFromArrayById(categories, filterData.productCategoryId)}
									</span>
								}
								{filterData.currencyId &&
									<span className="me-2">
										<b>{t('CURRENCY')}: </b> {findFromArrayById(currencies, filterData.currencyId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'posName')}>
										{t('POS')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'posName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'posName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'productName')}>
										{t('NAME_OF_PRODUCT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'productName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'productName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable text-center cursor" onClick={() => searchWithFilter(false, 'productBarcode')}>
										{t('BARCODE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'productBarcode') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'productBarcode') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable text-center cursor" onClick={() => searchWithFilter(false, 'saleQuantity')}>
										{t('NUMBER_OF_SALES')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'saleQuantity') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'saleQuantity') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable text-center cursor" onClick={() => searchWithFilter(false, 'totalIncome')}>
										{t('AMOUNT_RECEIVED')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'totalIncome') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'totalIncome') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable text-center cursor" onClick={() => searchWithFilter(false, 'totalSale')}>
										{t('SALE_AMOUNT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'totalSale') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'totalSale') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable text-center cursor" onClick={() => searchWithFilter(false, 'grossIncome')}>
										{t('GROSS_INCOME')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'grossIncome') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'grossIncome') && 'sort-active')}></i>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.posName}</td>
										<td>{item.productName}</td>
										<td className="text-center">{item.productBarcode}</td>
										<td className="text-center">{formatMoney(item.saleQuantity)}</td>
										<td className="text-center">{formatMoney(item.totalIncome)}</td>
										<td className="text-center">{formatMoney(item.totalSale)}</td>
										<td className="text-center">{formatMoney(item.grossIncome)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>
		</>
	)
}

export default Index
