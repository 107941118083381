import React, { useEffect, useState, useRef } from "react"
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { useReactToPrint } from 'react-to-print';
import XLSX from 'xlsx'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET, GET } from 'helpers/api'
import { formatMoney, formatDateWithTime } from 'helpers/helpers'
import logo from "../../../assets/images/logo.svg";

function Shift() {
	const { id } = useParams();
	const { t } = useTranslation()
	const URL = "/services/web/api/shift-pageList/" + id

	const printRef = useRef();

	const settings = useSelector(state => state.settings)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [dataInner, setDataInner] = useState({})

	async function openModal(item) {
		var response = await GET('/services/web/api/cashbox-zreport/' + item.id)
		if (response) {
			setDataInner(response)
			setShowModal(true)
		}
	}

	async function getData() {
		const response = await PGET(URL, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	function exportToExcel(item) {
		var data = [
			{ "A": t('POS'), "B": item.posName },
			{ "A": t('CASHBOX'), "B": item.cashboxName },
			{ "A": t('SHIFT_NUMBER'), "B": item.shiftNumber },
			{ "A": t('CASHIER'), "B": item.cashierName },
			{ "A": t('START_OF_SHIFT'), "B": formatDateWithTime(item.shiftOpenDate) },
			{ "A": t('END_OF_SHIFT'), "B": item.shiftCloseDate ? formatDateWithTime(item.shiftCloseDate) : '' },

		];
		if (item.zReportList.length > 0) {
			for (let i = 0; i < item.zReportList.length; i++) {
				if (item.zReportList[i]['amountIn'] || item.zReportList[i]['amountOut']) {
					if (item.zReportList[i]['amountIn']) {
						data.push({ "A": item.zReportList[i]['paymentPurposeName'] + ' ' + item.zReportList[i]['paymentPurposeName'] + ' ' + t('IN_BALANCE') + ' ' + item.zReportList[i]['currencyName'], "B": item.zReportList[i]['amountIn'] })
					}
					if (item.zReportList[i]['amountOut']) {
						data.push({ "A": item.zReportList[i]['paymentPurposeName'] + ' ' + item.zReportList[i]['paymentPurposeName'] + ' ' + t('OUT_BALANCE') + ' ' + item.zReportList[i]['currencyName'], "B": item.zReportList[i]['amountOut'] })
					}
				}
			}
		}
		if (item.debt) {
			data.push({ "A": t('SOLD_ON_CREDIT'), "B": item.debt })
		}

		for (let i = 0; i < item.balanceList.length; i++) {
			data.push({ "A": t('CASHBOX_BALANCE'), "B": item.balanceList[i].balance, "С": item.balanceList[i].currencyName })
		}
		data.push({ "A": t('NUMBER_OF_PRINTED_X_REPORTS'), "B": item.countRequest })
		var ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('SHIFTS') + ".xlsx");
	}

	async function paginate(data) {
		const response = await PGET(URL, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('SHIFTS')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									<th>{t('SHIFT_NUMBER')}</th>
									<th>{t('CASHIER')}</th>
									<th className="text-center">{t('OPEN_DATE')}</th>
									<th className="text-center">{t('CLOSE_DATE')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-end">{t('AMOUNT')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index} onClick={() => openModal(item)} className="bg-on-hover">
										<td>{item.shiftNumber}</td>
										<td>{item.cashierName}</td>
										<td className="text-center">{formatDateWithTime(item.openDate)}</td>
										<td className="text-center">{formatDateWithTime(item.closeDate)}</td>
										<td className="text-center">
											{item.statusCode === 99 &&
												<span className="bg-light bg-border text-white">{t('PAUSE')}</span>
											}
											{item.statusCode === 100 &&
												<span className="bg-danger bg-border text-white">{t('CLOSED')}</span>
											}
											{item.statusCode === 101 &&
												<span className="bg-success bg-border text-white">{t('OPEN')}</span>
											}
										</td>
										<td className="text-end">
											{item.balanceList?.map((item2, index2) => (
												<div key={index2}>
													{formatMoney(item2.balance)} {item2.currencyName}
												</div>
											))
											}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('ADDITIONAL_INFO')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('POS')}</span>
						<span>{dataInner.posName}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('CASHBOX')}</span>
						<span>{dataInner.cashboxName}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('SHIFT_NUMBER')}</span>
						<span>{dataInner.shiftNumber}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('CASHIER')}</span>
						<span>{dataInner.cashierName}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('START_OF_SHIFT')}</span>
						<span>{formatDateWithTime(dataInner.shiftOpenDate)}</span>
					</div>
					{dataInner.shiftCloseDate &&
						<div className="d-flex justify-content-between mb-1">
							<span>{t('END_OF_SHIFT')}</span>
							<span>{formatDateWithTime(dataInner.shiftCloseDate)}</span>
						</div>
					}
					{(dataInner && dataInner?.zReportList?.length > 0) &&
						<hr />
					}
					{dataInner.totalAmount &&
						<div className="d-flex justify-content-between mb-1 fz14">
							<span>{t('SALE_AMOUNT')}</span>
							<span>{formatMoney(dataInner.totalAmount)}</span>
						</div>
					}
					{dataInner?.zReportList?.map((item, index) => (
						<>
							{item.amountIn !== 0 &&
								<div className="d-flex justify-content-between">
									<div>
										{item.paymentTypeName} {item.paymentPurposeName}
										<span> {t('IN_BALANCE')} </span>
										({item.currencyName})
									</div>
									<div className="text-nowrap">{formatMoney(item.amountIn)}</div>
								</div>
							}
							{item.amountOut !== 0 &&
								<div className="d-flex justify-content-between">
									<div>
										{item.paymentTypeName} {item.paymentPurposeName}
										<span> {t('OUT_BALANCE')} </span>
										({item.currencyName})
									</div>
									<div className="text-nowrap">{formatMoney(item.amountOut)}</div>
								</div>
							}
						</>
					))
					}
					{dataInner.debt !== 0 &&
						<div className="d-flex justify-content-between">
							<span>{t('SOLD_ON_CREDIT')}</span>
							<span>{formatMoney(dataInner.debt)}</span>
						</div>
					}
					<hr />
					{dataInner?.balanceList?.map((item2, index2) => (
						<div className="d-flex justify-content-between">
							<h4><b>{t('CASHBOX_BALANCE')}</b></h4>
							<div key={index2}>
								<h4 className="text-no-wrap">
									<b>{formatMoney(item2.balance)} {item2.currencyName}</b>
								</h4>
							</div>
						</div>
					))
					}
					<div className="d-flex justify-content-between mb-1">
						<span>{t('NUMBER_OF_PRINTED_X_REPORTS')}</span>
						<span>{dataInner.countRequest}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('ACTION')}</span>
						<div className="d-flex">
							<div className="table-action-button table-action-primary-button me-2" onClick={handlePrint}>
								<i className="uil-print"></i>
							</div>
							<div className="table-action-button table-action-primary-button" onClick={() => exportToExcel(dataInner)}>
								<i className="uil-file-alt"></i>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<div id="print" className="d-none">
				<div className="color-00 p-2" ref={printRef}>
					<div className="d-flex justify-content-center mb-1">
						<div className="d-flex flex-column">
							<div className="d-flex justify-content-center mb-1">
								<img src={logo} alt="" width="130" />
							</div>
							<span className="text-center text-uppercase fz14">X {t('REPORT')}</span>
						</div>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('POS')}</span>
						<span>{dataInner.posName}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('CASHBOX')}</span>
						<span>{dataInner.cashboxName}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('SHIFT_NUMBER')}</span>
						<span>{dataInner.shiftNumber}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('CASHIER')}</span>
						<span>{dataInner.cashierName}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('START_OF_SHIFT')}</span>
						<span>{dataInner.shiftOpenDate ? formatDateWithTime(dataInner.shiftOpenDate) : ''}</span>
					</div>
					{dataInner.shiftCloseDate &&
						<div className="d-flex justify-content-between mb-1 fz14">
							<span>{t('END_OF_SHIFT')}</span>
							<span>{dataInner.shiftCloseDate ? formatDateWithTime(dataInner.shiftCloseDate) : ''}</span>
						</div>
					}
					**************************************************************************************
					{dataInner.totalAmount &&
						<div className="d-flex justify-content-between mb-1 fz14">
							<span>{t('SALE_AMOUNT')}</span>
							<span>{formatMoney(dataInner.totalAmount)}</span>
						</div>
					}
					{dataInner?.zReportList?.map((item, index) => (
						<div key={index}>
							{(item.amountIn || item.amountOut) &&
								<div className="d-flex justify-content-between mb-1 fz14">
									<span>
										<span className="text-nowrap me-1">{item.paymentPurposeName}</span>
										<span className="text-nowrap me-1">{item.paymentTypeName}</span>
										{item.amountIn > 0 &&
											<span className="text-nowrap me-1">{t('IN_BALANCE')}</span>
										}
										{item.amountOut > 0 &&
											<span className="text-nowrap me-1">{t('OUT_BALANCE')}</span>
										}
										({item.currencyName})
									</span>
									{item.amountIn > 0 &&
										<span>{formatMoney(item.amountIn)}</span>
									}
									{item.amountOut > 0 &&
										<span>{formatMoney(item.amountOut)}</span>
									}
								</div>
							}
						</div>
					))
					}
					{dataInner.debt > 0 &&
						<div className="d-flex justify-content-between mb-1 fz14">
							<span>{t('SOLD_ON_CREDIT')}</span>
							<span>{formatMoney(dataInner.debt)}</span>
						</div>
					}
					**************************************************************************************
					{dataInner?.balanceList?.map((item2, index2) => (
						<div className="d-flex justify-content-between mb-1">
							<h5 className="color-00"><b>{t('CASHBOX_BALANCE')}</b></h5>
							<div key={index2}>
								<h5 className="text-no-wrap color-00">
									<b>{formatMoney(item2.balance)} {item2.currencyName}</b>
								</h5>
							</div>
						</div>
					))
					}
					<div className="d-flex justify-content-between fz14">
						<span>{t('NUMBER_OF_PRINTED_X_REPORTS')}</span>
						<span>{dataInner.countRequest}</span>
					</div>
				</div>
			</div>

		</>
	)
}

export default Shift
