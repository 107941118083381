import axios from 'axios'
import { showLoader, hideLoader } from '../store/loader/actions';
import { store } from '../store/index'
import { toast } from 'react-toastify'

import { clearTemporaryStorage } from 'helpers/helpers'

import i18n from '../i18n'
import languages from 'common/languages';
const baseURL = 'https://cabinet.sdpos.io';

//axios.defaults.timeout = 1000;

const axiosClient = axios.create({
	baseURL: baseURL,
	//timeout: 2000,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		"Accept-Language": localStorage.getItem("I18N_LANGUAGE"),
		"Language": localStorage.getItem("I18N_LANGUAGE")
	},
});

const axiosFile = axios.create({
	baseURL: baseURL,
	// timeout: 2000,
	withCredentials: true,
	headers: {
		'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
		'Content-Type': 'multipart/form-data',
		'Accept': 'application/json',
		"withCredentials": true,
		"Accept-Language": localStorage.getItem("I18N_LANGUAGE"),
		"Language": localStorage.getItem("I18N_LANGUAGE")
	},
});

async function checkToken() {

	var tokenTime = localStorage.getItem('tokenTime');
	var difference = Math.floor(((Date.now() - tokenTime) / 1000) / 60);
	if (difference < 55) {
		return
	} else {
		const user = localStorage.getItem('authUser')
		console.log(user);
		if (!user) {
			window.location.href = process.env.REACT_APP_PROD_SERVER_URL + "/#/login";
			return;
		}
		await axiosClient.post('/auth/login', user).then(response => {
			if (!response.data.activated) {
				toast.error(i18n.t('ERROR') + ': ' + i18n.t('YOUR_ACCOUNT_BLOCKED'))
				clearTemporaryStorage()
				window.location.href = "https://cabinet.sdpos.io/#/auth/login";
				return
			}
			localStorage.setItem('access_token', response.data.access_token)
			localStorage.setItem("tokenTime", JSON.stringify(new Date().getTime()));
		}).catch(error => {
			httpStatusChecker(error)
		})
		return
	}
}

export async function PGET(URL, payload = {}, settings = {}) {
	var params = "";
	if (Object.entries(payload).length > 0) {
		params = getPath(payload, URL);
	}

	if (settings.loader) {
		store.dispatch(showLoader());
		await checkToken()
		const data = await axiosClient.get(
			URL + params,
			{ headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token') } }
		)
			.catch(error => {
				httpStatusChecker(error)
			})
		store.dispatch(hideLoader());
		return data
	} else {
		await checkToken()
		const data = await axiosClient.get(
			URL + params,
			{ headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token') } }
		)
		return data
	}

}

export async function GET(URL, payload = {}, settings = {}) {
	var params = "";
	if (Object.entries(payload).length > 0) {
		params = getPath(payload);
	}

	if (settings.loader) {
		store.dispatch(showLoader());
		await checkToken()
		const data = await axiosClient.get(
			URL + params,
			{ headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token') } }
		)
			.catch(error => {
				httpStatusChecker(error)
				return error
			})
		store.dispatch(hideLoader());
		if (data?.response?.status >= 300) {
			return data
		}
		return data?.data
	} else {
		await checkToken()
		const data = await axiosClient.get(
			URL + params,
			{ headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token') } }
		)
		return data.data
	}
}

export async function POST(URL, payload, settings = {}) {
	let jsonData = JSON.stringify(payload);
	if (settings.loader) {
		store.dispatch(showLoader());
		await checkToken()
		const data = await axiosClient.post(
			URL, jsonData,
			{ headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token') } }
		)
			.catch(error => {
				httpStatusChecker(error)
			})
		store.dispatch(hideLoader());
		if (data && data.status === 200) {
			toast.dismiss();
			toast.success(i18n.t('SUCCESS'))
		}
		return data
	} else {
		await checkToken()
		const data = await axiosClient.post(`${URL}`, jsonData, { headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token') } })
			.catch(error => {
				httpStatusChecker(error)
			})
		return data
	}
}

export async function PUT(URL, payload, settings = {}) {
	let jsonData = JSON.stringify(payload);
	if (settings.loader) {
		store.dispatch(showLoader());
		await checkToken()
		const data = await axiosClient.put(
			URL, jsonData,
			{ headers: { Authorization: localStorage.getItem('access_token') === null ? '' : 'Bearer ' + localStorage.getItem('access_token') } }
		)
			.catch(error => {
				return error
			});

		store.dispatch(hideLoader());
		if (data && data.status === 200) {
			toast.dismiss();
			toast.success(i18n.t('SUCCESS'))
		} else {
			httpStatusChecker(data)
		}

		return data?.data
	} else {
		await checkToken()
		const data = await axiosClient.put(`${URL}`, jsonData, { headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token') } })
		return data?.data
	}

}

export async function FILE(URL, payload, settings = {}) {
	await checkToken()
	if (settings.loader) {
		store.dispatch(showLoader());
		const data = await axiosFile.post(`${URL}`, payload, { headers: { Authorization: localStorage.getItem('access_token') === null ? '' : 'Bearer ' + localStorage.getItem('access_token') } })
			.catch(error => {
				store.dispatch(hideLoader());
				toast.error('Ошибка' + error)
				return error
			});
		store.dispatch(hideLoader());
		return data.data

	} else {
		const data = await axiosFile.post(`${URL}`, payload, { headers: { "Content-Type": "multipart/form-data", Authorization: localStorage.getItem('access_token') === null ? '' : 'Bearer ' + localStorage.getItem('access_token') } });
		return data.data
	}
}

export async function DOWNLOAD_FILE(URL) {
	await checkToken()
	store.dispatch(showLoader());
	var response = await axios({
		url: `${baseURL + URL}`,
		method: 'GET',
		responseType: 'blob',
		headers: {
			Authorization: localStorage.getItem('access_token') === null ? '' : 'Bearer ' + localStorage.getItem('access_token'),
			"Accept-Language": localStorage.getItem("I18N_LANGUAGE"),
		}
	})
	store.dispatch(hideLoader());
	return response.data
}

export async function DELETE(URL, payload, settings = {}) {
	await checkToken()
	if (settings.loader) {
		store.dispatch(showLoader());
		const data = await axiosClient.delete(`${URL}`, { headers: { Authorization: localStorage.getItem('access_token') === null ? '' : 'Bearer ' + localStorage.getItem('access_token') } })
			.catch(error => {
				httpStatusChecker(error)
				return error
			});
		store.dispatch(hideLoader());
		if (data && data.status === 200) {
			toast.dismiss();
			toast.success(i18n.t('DELETED'))
		} else {
			httpStatusChecker(data)
		}
		return data.data
	} else {
		const data = await axiosClient.delete(`${URL}`, { headers: { Authorization: localStorage.getItem('access_token') === null ? '' : 'Bearer ' + localStorage.getItem('access_token') } })
			.catch(error => {
				httpStatusChecker(error)
				return error
			});
		store.dispatch(hideLoader());
		return data.data
	}
}

export async function GUESTGET(URL, payload, settings = {}) {
	if (settings.loader) {
		store.dispatch(showLoader());
		const data = await axiosClient.get(`${URL}`).then(response => response.data);
		store.dispatch(hideLoader());
		return data
	} else return axiosClient.get(`${URL}`).then(response => response.data);
}

export async function GUESTPOST(URL, payload, settings = {}) {
	let jsonData = JSON.stringify(payload);
	if (settings.loader) {
		//store.dispatch(showLoader());
		const data = await axiosClient.post(
			`${URL}`,
			jsonData,
			{ headers: { Authorization: localStorage.getItem('access_token') === null ? '' : 'Bearer ' + localStorage.getItem('access_token') } }
		)
			.catch(error => {
				httpStatusCheckerGuess(error)
				return error
			});
		//store.dispatch(hideLoader());
		return data.data
	} else {
		return await axiosClient.post(`${URL}`, jsonData).then(response => response.data);
	}
}

function getPath(payload, url) {
	let iterations = Object.entries(payload).length;
	var pathArr = "?";
	if (url)
		url.includes("?") ? pathArr = '&' : pathArr = '?'

	for (let key in payload) {
		if (!--iterations) {
			pathArr += key + "=" + payload[key];
		} else {
			pathArr += key + "=" + payload[key] + "&";
		}
	}
	return pathArr;
}

function httpStatusChecker(error) {
	toast.dismiss();

	if (error?.response?.status === 400) {
		toast.error(i18n.t('ERROR') + ': ' + error.response.data.message)
		return;
	}
	if (error?.response?.status === 401) {
		if (process.env.NODE_ENV === 'production') {
			window.location.href = "https://cabinet.sdpos.io/#/login";
		}
		toast.error(i18n.t('ERROR') + ': ' + i18n.t('LOGIN_OR_PASSWORD_INVALID'))
		return;
	}
	if (error?.response?.status === 404) {
		toast.error(i18n.t('ERROR') + ': ' + i18n.t('NOT_FOUND'))
		return;
	}
	if (error?.response?.status === 415) {
		toast.error(i18n.t('ERROR') + ': ' + i18n.t('ERROR'))
		return;
	}
	if (error?.response?.status === 500) {
		toast.error(i18n.t('ERROR') + ': ' + error.response.data.message)
		return;
	}
	if (!error.status) { // если нету интернета то выходит эта ошибка
		return
	}
}

function httpStatusCheckerGuess(error) {
	if (error?.response?.status === 400) {
		toast.error(i18n.t('ERROR') + ': ' + error.response.data.message)
	}
	if (error?.response?.status === 401) {
		toast.error(i18n.t('ERROR') + ': ' + i18n.t('LOGIN_OR_PASSWORD_INVALID'))
	}
}