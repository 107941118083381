import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET, GET } from 'helpers/api'
import { formatMoney, formatDate } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const settings = useSelector(state => state.settings)

	const [poses, setPoses] = useState([])
	const [wmsId, setWmsId] = useState(0)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function getData() {
		var temporaryPoses = await GET("/services/wms/api/wms-helper", {}, { loader: true })
		temporaryPoses.unshift({ 'id': 0, 'name': t('ALL') })
		setPoses(temporaryPoses)
		setWmsId(temporaryPoses[0]['id'])
		const response = await PGET(`/services/wms/api/write-off-pageList?wmsId=${temporaryPoses[0]['id']}`)
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function handleSelect(option) {
		const response = await PGET('/services/wms/api/write-off-pageList', { wmsId: option.id }, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
			setWmsId(option.id)
		}
	}

	async function paginate(data) {
		const response = await PGET(`/services/wms/api/write-off-pageList?wmsId=${wmsId}`, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('WRITE_OFF')}
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/wms/write-off/create">
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="row g-0 mb-3">
						<div className="col-md-2">
							<Search url={`/services/wms/api/write-off-pageList?wmsId=${wmsId}`} search={search} getData={getData}></Search>
						</div>
						<div className="col-md-2">
							<Select
								options={poses}
								value={poses?.find(option => option.id === wmsId)}
								onChange={handleSelect}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('WAREHOUSE')}</th>
									<th className="text-center">№ {t('WRITE_OFF')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('DATE_OF_RETURN')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.wmsName}</td>
										<td className="text-center">{item.writeOffNumber}</td>
										<td className="text-center">{formatMoney(item.totalAmount)}</td>
										<td className="text-center">{formatDate(item.createdDate)}</td>
										<td className="d-flex justify-content-center">
											<Link to={'/wms/write-off/preview/' + item.id}>
												<div className="table-action-button table-action-primary-button">
													<i className="uil-eye"></i>
												</div>
											</Link>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
