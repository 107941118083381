import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import XLSX from 'xlsx';
import { Modal, Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import { toast } from 'react-toastify'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { SET_REPORT_SELECTED_CLIENT } from 'store/report/actions';
import { PGET, GET, POST } from 'helpers/api'
import { formatMoney, regexPhoneNumber, formatDateBackend, returnSign, formatDate, findFromArrayById } from 'helpers/helpers'

function Index({ selectReportType }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()



	const paymentTypes = [{ 'id': 1, 'name': t('CASH') }, { 'id': 2, 'name': t('TERMINAL') }, { 'id': 3, 'name': t('MONEY_TRANSFER') }]
	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]
	const paymentPurposes = [{ "id": 4, "name": "Долг" }, { "id": 5, "name": "Погашение задолженности" }]
	const [totalAmount, setTotalAmont] = useState([])
	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [modalPayment, setModalPayment] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [filterPoses, setFilterPoses] = useState([])
	const [filterData, setFilterData] = useState({
		startDate: null,
		endDate: null,
		posId: null,
		paymentPurposeId: null,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/services/web/api/report-client-pageList',
		response: null
	})

	function redirectToInner(item) {
		dispatch(SET_REPORT_SELECTED_CLIENT(item))
		selectReportType(14)
	}

	function redirectToSelectOnSale(item) {
		dispatch(SET_REPORT_SELECTED_CLIENT(item))
		selectReportType(15)
	}

	function selectItem(item) {
		var itemCopy = { ...item }
		itemCopy.posId = poses[0]['id']
		itemCopy.paymentTypeId = 1
		itemCopy.amountTypeId = 1
		itemCopy.currencyId = 1
		itemCopy.amountIn = ''
		setSelectedItem(itemCopy)
	}

	async function payment(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }

		var sendData = {}
		sendData.amountIn = selectedItemCopy.amountIn
		sendData.currencyId = selectedItemCopy.currencyId
		sendData.clientId = selectedItemCopy.clientId
		sendData.paymentTypeId = selectedItemCopy.paymentTypeId
		sendData.posId = selectedItemCopy.posId
		sendData.note = selectedItemCopy.note

		var response = await POST('/services/web/api/clients-payment', sendData, { loader: true })

		if (response) {
			showPaymentModal(false)
			getData()
		}
	}

	async function showPaymentModal(bool) {
		var paymentDataCopy = {}
		if (!bool) {
			paymentDataCopy.paymentTypeId = 1
			paymentDataCopy.amountTypeId = 1
			paymentDataCopy.currencyId = 1
			paymentDataCopy.amountIn = ''
		} else {
			if (!selectedItem?.clientId) {
				toast.warning(t('NO_CLIENT_SELECTED'))
				return
			}
		}

		setModalPayment(bool)
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getData() {
		var filterUrl = ''
		const response = await PGET(`/services/web/api/report-client-pageList${filterUrl}`, {}, { loader: true })
		if (response) {
			setPagination({ ...pagination, 'url': "/services/web/api/report-client-pageList", 'response': response })
			setData(response.data)
		}

		const temporaryTotalAmount = await GET(`/services/web/api/report-client-total${filterUrl}`)
		setTotalAmont(temporaryTotalAmount)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)

			const responseCopy = [...response]
			responseCopy.unshift({ 'id': 0, 'name': t('ALL') })
			setFilterPoses(responseCopy)
		}
	}

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = "/services/web/api/report-client-pageList"
		var urlParams = ""
		checkFilter()

		if (filterData.startDate)
			urlParams += returnSign(urlParams) + 'startDate=' + filterData.startDate
		if (filterData.endDate)
			urlParams += returnSign(urlParams) + 'endDate=' + filterData.endDate
		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.paymentPurposeId)
			urlParams += returnSign(urlParams) + 'paymentPurposeId=' + filterData.paymentPurposeId
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/web/api/report-client-pageList" + urlParams !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		if (tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = "/services/web/api/report-client-excel" + urlParams
			const response = await GET(filterUrl)
			var excelHeaders = [[t('CLIENT'), t('MOBILE'), t('PHONE'), t('NOTE'), t('CUSTOMER_DEBT') + (' (USD)'), t('CUSTOMER_DEBT') + (' (Сум)'),]]
			for (let i = 0; i < response.length; i++) {
				var excelItems = []
				excelItems.push(response[i].name)
				excelItems.push(response[i].phone1)
				excelItems.push(response[i].phone2)
				excelItems.push(response[i].note)
				for (let j = 0; j < response[i]['balanceList'].length; j++) {
					if (response[i]['balanceList'].length === 1) {
						if (response[i]['balanceList'][j]['currencyName'] === "USD") {
							excelItems.push(0)
							excelItems.push(response[i]['balanceList'][j]['totalAmount'])
						}
						if (response[i]['balanceList'][j]['currencyName'] === t('SUM')) {
							excelItems.push(0)
							excelItems.push(response[i]['balanceList'][j]['totalAmount'])
						}
					}
					if (response[i]['balanceList'].length === 2) {
						if (response[i]['balanceList'][j]['currencyName'] === "USD") {
							excelItems.push(response[i]['balanceList'][j]['totalAmount'])
						}
						if (response[i]['balanceList'][j]['currencyName'] === t('SUM')) {
							excelItems.push(response[i]['balanceList'][j]['totalAmount'])
						}
					}
				}

				excelHeaders.push(excelItems)
			}
			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('CLIENTS') + ".xlsx");
		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			}
			filterUrl = "/services/web/api/report-client-total" + urlParams
			const temporaryTotalAmount = await GET(filterUrl)
			setTotalAmont(temporaryTotalAmount)

			setDropdownIsOpen(false)
		}
	}

	function resetFilter() {
		setFilterData({
			startDate: null,
			endDate: null,
			posId: null,
			paymentPurposeId: null,
			productCategoryId: null,
			groupBy: false,
			search: '',
		})
		setFilterDataExist(false)
		getData()
	}

	function checkFilter() {
		if (
			filterData.startDate === null &&
			filterData.endDate === null &&
			filterData.posId === null &&
			filterData.paymentPurposeId === null &&
			filterData.groupBy === false &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		getData()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2" onClick={() => showPaymentModal(true)}>
									{t('PAY')}
								</button>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2" onClick={() => searchWithFilter(true)}>
									EXCEL
								</button>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={filterPoses}
													value={filterPoses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('EXPENSE_TYPE')}</label>
												<Select
													options={paymentPurposes}
													value={paymentPurposes.find(option => option.id === filterData.paymentPurposeId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'paymentPurposeId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('PERIOD')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.startDate ? new Date(filterData.startDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.endDate ? new Date(filterData.endDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4>
									<b>{t('TOTAL_AMOUNT')}</b>:
								</h4>
							</div>
							<div className="vertical-center">
								{totalAmount.map((item, index) => (
									<h5 className="text-end" key={index}>
										<span className="me-1">{formatMoney(item.totalAmount)}</span>
										<b>{item.currencyName}</b>
									</h5>
								))
								}
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{filterData.posId ?
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(filterPoses, filterData.posId)}
									</span>
									:
									''
								}
								{filterData.paymentPurposeId &&
									<span className="me-2">
										<b>{t('EXPENSE_TYPE')}: </b> {findFromArrayById(paymentPurposes, filterData.paymentPurposeId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-hover cursor mb-0">
							<thead>
								<tr>
									<th>{t('CLIENT')}</th>
									<th>{t("MOBILE")}</th>
									<th>{t("PHONE")}</th>
									<th>{t("NOTE")}</th>
									<th className="text-center">{t("CUSTOMER_DEBT")}</th>
									<th className="text-center">{t("ACTION")}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className={'tr-middle ' + (
										(selectedItem && selectedItem.clientId === item.clientId) ? 'table-tr-active' : '')}
										key={index} onClick={() => selectItem(item)}>
										<td>
											<span className="text-primary cursor table-first-column-clickable"
												onClick={() => redirectToInner(item)}>
												{item.rowNum}. {item.name}
											</span>
										</td>
										<td>{regexPhoneNumber(item.phone1)}</td>
										<td>{regexPhoneNumber(item.phone2)}</td>
										<td>{item.note}</td>
										<td className="text-center">
											{item?.balanceList?.map((item, index) => (
												<div className={item.totalAmount > 0 ? 'text-success' : 'text-danger'} key={index}>
													<span className="me-1">{formatMoney(item.totalAmount)}</span>
													<b>{item.currencyName}</b>
												</div>
											))
											}
										</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="table-action-button table-action-primary-button me-2"
													title={t('DEBTOR')}
													onClick={() => redirectToInner(item)}>
													<i className="uil-credit-card-search"></i>
												</div>
												<div className="table-action-button table-action-primary-button"
													title={t('CHEQUES')}
													onClick={() => redirectToSelectOnSale(item)}>
													<i className="uil-receipt"></i>
												</div>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => showPaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('PAY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('POS')}</label>
							<Select
								options={poses}
								value={poses.find(option => option.id === selectedItem.posId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'posId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('PAYMENT_METHOD')}</label>
							<Select
								options={paymentTypes}
								value={paymentTypes.find(option => option.id === selectedItem.paymentTypeId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'paymentTypeId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('CHOOSE_PAYMENT_TYPE')}</label>
							<Select
								options={currencies}
								value={currencies.find(option => option.id === selectedItem.currencyId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'currencyId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="amountIn" autoFocus step="any"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amountIn': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('NOTE')}</label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => showPaymentModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100" disabled={!selectedItem.amountIn}>
								{t('SAVE')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}
		</>
	)
}

export default Index
