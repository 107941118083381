const initialState = {
	'filterData': {},
}

const filterData = function (state = initialState, action) {
	switch (action.type) {
		case "SET_FILTER_DATA":
			return { ...state, 'filterData': action.payload };
		default:
			return state;
	}
};

export default filterData