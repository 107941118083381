import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { GUESTGET } from 'helpers/api'
import { formatMoney, formatDateWithTime } from 'helpers/helpers'
import logo from "assets/images/logo.svg";

function Cheque() {
	const { t } = useTranslation()
	const { shiftId, chequeNumber } = useParams();

	const [data, setData] = useState({});

	async function getData() {
		const response = await GUESTGET(`/services/web/api/cheque-byQR/${shiftId}/${chequeNumber}`, {}, { loader: true })
		setData(response)
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex justify-content-center">
				<div className="cheque-no-auth">
					<div className="d-flex justify-content-center mt-3 mb-1">
						<div className="d-flex flex-column">
							<div className="d-flex justify-content-center mb-1">
								<img src={logo} width={120} alt="logo" />
							</div>
							<h5 className="text-center fw-700 mb-1">{t('DUPLICATE')}</h5>
							{/* <h5 className="text-center fw-700 mb-1">{data.posName}</h5>
							<h6 className="text-center fw-600 mb-1">{t('PHONE')}: {data.posPhone}</h6>
							<h6 className="text-center fw-500 mb-1">{t('ADDRESS')}: {data.posAddress}</h6> */}
						</div>
					</div>

					<div className="cheque-block-1 cheque-border-bottom mb-2">
						<div className="d-flex justify-content-between px-2">
							<p className="fw-600">{t('CASHIER')}</p>
							<p>{data.cashierName}</p>
						</div>
						<div className="d-flex justify-content-between px-2">
							<p className="fw-600">{t('CHEQUE')} № </p>
							<p>{data.chequeNumber}</p>
						</div>
						{data.tin &&
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('TIN')}</p>
								<p>{data.tin}</p>
							</div>
						}
						<div className="d-flex justify-content-between px-2">
							<p className="fw-600">{t('DATE')}</p>
							<p>{formatDateWithTime(data.chequeDate)}</p>
						</div>
					</div>

					<div className="cheque-block-2 cheque-border-bottom px-2">
						<table className="custom-cheque-table w-100 fz-14">
							<thead>
								<tr>
									<th className="w-50">№ {t('PRODUCT')}</th>
									<th>{t('QUANTITY')}</th>
									<th className="text-end">{t('PRICE')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.itemsList?.map((item, index) => (
									<tr key={index}>
										{/* column 1 */}
										<td className="d-flex" style={{ 'whiteSpace': 'break-spaces' }}>
											{(item.returned === 0 || item.returned === 1) &&
												<span>{index + 1}. {item.productName}</span>
											}
											{item.returned === 2 &&
												<span><del>{index + 1} {item.productName}</del></span>
											}
										</td>
										{/* column 1 */}

										{/* column 2 */}
										{(item.vat !== 0 && item.returned === 0) &&
											<td className="text-left" style={{ 'verticalAlign': 'top' }}>
												{item.returned === 0 &&
													<span className="text-nowrap" >{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</span>
												}
												{item.returned === 1 &&
													<span>
														<div className="text-nowrap">{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</div>
														<div className="text-nowrap"><del>{item.returnedQuantity === 0 ? formatMoney(item.quantity) : formatMoney(item.returnedQuantity)} X {formatMoney(item.salePrice)}</del></div>
													</span>
												}
												{item.returned === 2 &&
													<div className="text-nowrap"><del>{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</del></div>
												}
											</td>
										}
										{(item.vat === 0 && item.returned === 0) &&
											<td className="text-left" style={{ 'verticalAlign': 'top' }}>
												{item.returned === 0 &&
													<span className="text-nowrap">{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</span>
												}
												{item.returned === 1 &&
													<span>
														<div className="text-nowrap">{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</div>
														<div className="text-nowrap"><del>{item.returnedQuantity === 0 ? formatMoney(item.quantity) : formatMoney(item.returnedQuantity)} X {formatMoney(item.salePrice)}</del></div>
													</span>
												}
												{item.returned === 2 &&
													<div className="text-nowrap"><del>{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</del></div>
												}
											</td>
										}

										{/* column 2 */}

										{/* column 3 */}
										{(item.vat !== 0 && item.returned === 0) &&
											<td className="text-end">
												{formatMoney(item.totalPrice)} <br />
												<div className="text-end text-nowrap">
													{t('VAT')} {formatMoney(item.vat)}%
													{item.vatAmount > 0 &&
														<span className="ms-1">{formatMoney(item.vatAmount)}</span>
													}
												</div>
											</td>
										}
										{(item.vat === 0 && item.returned === 0) &&
											<td className="text-end align-top text-nowrap">
												{formatMoney(item.totalPrice)}
												<br />
												<span>{t('VAT_TEXT')}</span>
											</td>
										}
										{(item.vat !== 0 && item.returned !== 0) &&
											<td colSpan="2">
												<div className="d-flex justify-content-between">
													<div className={"text-nowrap " + (item.returned !== 0 && 'del')}>
														{item.quantity} X {formatMoney(item.salePrice)}
														{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
															<br />
														}
														{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
															<span>
																{item.returnedQuantity} X {formatMoney(item.salePrice)}
															</span>
														}
													</div>
													<div className={"text-end text-nowrap " + (item.returned !== 0 && 'del')}>
														{formatMoney(item.totalPrice)}
														{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
															<>
																<br />
																<span>
																	{formatMoney(item.returnedPrice)}
																</span>
															</>
														}
													</div>
												</div>
												<div className={"text-end text-nowrap " + (item.returned !== 0 && 'del')}>
													{t('VAT')} {formatMoney(item.vat)}%
													{item.vatAmount > 0 &&
														<span className="ms-1">{formatMoney(item.vatAmount)}</span>
													}
												</div>
												<div className="text-end">
													{item.returned === 1 &&
														<div className="text-uppercase text-nowrap">{t('PRODUCT_PARTIALLY_RETURNED')}</div>
													}
													{item.returned === 2 &&
														<div className="text-uppercase text-nowrap">{t('PRODUCT_RETURNED')}</div>
													}
												</div>
											</td>
										}
										{(item.vat === 0 && item.returned !== 0) &&
											<td colSpan="2">
												<div className="d-flex justify-content-between">
													<div className={"text-nowrap " + (item.returned !== 0 && 'del')}>
														{item.quantity} X {formatMoney(item.salePrice)}
														{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
															<br />
														}
														{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
															<span>
																{item.returnedQuantity} X {formatMoney(item.salePrice)}
															</span>
														}
													</div>
													<div className={"text-end text-nowrap " + (item.returned !== 0 && 'del')}>
														{formatMoney(item.totalPrice)}
														{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
															<>
																<br />
																<span>
																	{formatMoney(item.returnedPrice)}
																</span>
															</>
														}
													</div>
												</div>
												<div className={"text-end text-nowrap " + (item.returned !== 0 && 'del')}>
													{t('VAT')} {item.vat}%
													{item.vatAmount > 0 &&
														<span className="ms-1">{formatMoney(item.vatAmount)}</span>
													}
												</div>
												<div className="text-end">
													{item.returned === 1 &&
														<div className="text-uppercase text-nowrap">{t('PRODUCT_PARTIALLY_RETURNED')}</div>
													}
													{item.returned === 2 &&
														<div className="text-uppercase text-nowrap">{t('PRODUCT_RETURNED')}</div>
													}
												</div>
											</td>
										}
										{/* column 3 */}
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<div className="cheque-block-3 cheque-border-bottom mb-5">
						<div className="d-flex justify-content-between px-2">
							<p className="fw-600">{t('SALE_AMOUNT')}</p>
							<p>{formatMoney(data.totalPrice)}</p>
						</div>
						<div className="d-flex justify-content-between px-2">
							<p className="fw-600">{t('DISCOUNT')}</p>
							{data.discount > 0 ?
								<p>{formatMoney((data.totalPrice * data.discount) / 100)}</p>
								:
								<p>{formatMoney(0)}</p>
							}
						</div>
						<div className="d-flex justify-content-between px-2">
							<p className="fw-600 fz-30">{t('TO_PAY')}</p>
							<p className="fw-600 fz-30">{formatMoney(data.totalPrice - ((data.totalPrice * data.discount) / 100))}</p>
						</div>
						<div className="d-flex justify-content-between px-2">
							<p className="fw-600">{t('PAID')}</p>
							<p className="fw-600">{formatMoney(data.paid)}</p>
						</div>
						{data.vat &&
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('VAT')} {data.vat}%</p>
								<p className="fw-600">{formatMoney(data.totalVatAmount)}</p>
							</div>
						}
						{data.saleCurrencyId > 0 &&
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('CURRENCY')}</p>
								{data.saleCurrencyId === 1 &&
									<p className="text-capitalize">{t('SUM')}</p>
								}
								{data.saleCurrencyId === 2 &&
									<p>USD</p>
								}
							</div>
						}
						{data.label &&
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('MARKING')}</p>
								<p className="fw-600">{data.label ? t('YES') : t('NO')}</p>
							</div>
						}
						{data.transactionsList?.map((item, index) => (
							<div className="d-flex justify-content-between px-2" key={index}>
								<p className="fw-600">{item.paymentTypeName}</p>
								<p>{formatMoney(item.amountIn)}</p>
							</div>
						))
						}
						{data.loyaltyClientName &&
							<div className="d-flex justify-content-between">
								<p className="fw-600">{t('CLIENT')}</p>
								<p>{data.loyaltyClientName}</p>
							</div>
						}
						{data.loyaltyBonus > 0 &&
							<div className="d-flex justify-content-between">
								<p className="fw-600">Sales Doctor Loyalty {t('BONUS')}</p>
								<p>{data.loyaltyBonus}</p>
							</div>
						}
						<div className="d-flex justify-content-between px-2">
							<p>{t('CHANGE2')}</p>
							<p>{formatMoney(data.change)}</p>
						</div>
						{data.clientAmount > 0 &&
							<div className="d-flex justify-content-between px-2">
								<p>{t('AMOUNT_OF_DEBT')}</p>
								<p>{formatMoney(data.clientAmount)}</p>
							</div>
						}
						{Number(data.clientAmount) > 0 &&
							<div className="d-flex justify-content-between px-2">
								<p>{t('DEBTOR')}</p>
								{data.clientName &&
									<p>{data.clientName}</p>
								}
							</div>
						}
						{(Number(data.clientAmount) === 0 && data.clientName) &&
							<div className="d-flex justify-content-between px-2">
								<p>{t('CLIENT')}</p>
								{data.clientName &&
									<p>{data.clientName}</p>
								}
							</div>
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default Cheque