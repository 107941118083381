import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Select from 'react-select'

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'
import TableControl from 'pages/parts/TableControl'

import { PGET, GET, PUT } from 'helpers/api'
import { formatMoney, returnSign, findFromArrayById, formatDateWithTime } from 'helpers/helpers'

function Revaluation() {
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)

	const [pagination, setPagination] = useState({
		url: '/services/web/api/revaluation-pageList',
		response: null,
		increment: 0,
	})
	const [pagination2, setPagination2] = useState({
		url: '',
		response: null,
		increment: 0,
	})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [dataDuplicate, setDataDuplicate] = useState({})
	const [changedItem, setChangedItem] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [showHistoryModal, setShowHistoryModal] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [modalTableControl, setModalTableControl] = useState(false)

	const [filterData, setFilterData] = useState({ posId: null, search: '', })
	const [data, setData] = useState([])
	const [products, setProducts] = useState([])
	const [poses, setPoses] = useState([])

	async function update() {
		const response = await PUT('/services/web/api/revaluation', changedItem, { loader: true })
		if (response) {
			setPagination({ ...pagination, increment: pagination.increment + 1 })
		}
		setShowModal(false)
	}

	function selectedItemEdit(index) {
		var dataCopy = [...data]
		setDataDuplicate({ ...dataCopy[index] })

		dataCopy[index].showInput = true
		for (let i = 0; i < dataCopy.length; i++) {
			if (!dataCopy[i].showInput) {
				dataCopy[i].visible = false
			}
		}
	}

	function cancelSelectedItemEdit(index) {
		var dataCopy = [...data]
		dataCopy[index] = { ...dataDuplicate }
		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i].visible = true
			dataCopy[i].showInput = false
		}
		setData(dataCopy)
	}

	function confirmModalShow(index) {
		var dataCopy = [...data]
		if (
			(dataCopy[index].price !== "" && dataCopy[index].price > 0.0001) &&
			(dataCopy[index].wholesalePrice !== "" && Number(dataCopy[index].wholesalePrice) >= Number(dataCopy[index].price)) &&
			(dataCopy[index].salePrice !== "" && Number(dataCopy[index].salePrice) >= Number(dataCopy[index].wholesalePrice))
		) {
			setChangedItem({ ...dataCopy[index] })
			setShowModal(true)
		} else {
			toast.dismiss();
			toast.error(t('ERROR') + ': ' + t('CHECK_FILLED_INPUTS'))
		}
	}

	function handleInputChange(e, index) {
		var dataCopy = [...data]
		dataCopy[index][e.target.name] = e.target.value
		setData(dataCopy)
	}

	async function searchWithFilter(filterParams = { passedFilterData: {} }) {
		var filterDataCopy = { ...filterData }
		if (filterParams.passedFilterData && Object.keys(filterParams.passedFilterData).length > 0) {
			filterDataCopy = filterParams.passedFilterData
		}

		var filterUrl = '/services/web/api/revaluation-pageList'
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterDataCopy.posId
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search

		if ("/services/web/api/revaluation-pageList" + urlParams !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		if (response) {
			for (let i = 0; i < response.data?.length; i++) {
				response.data[i]['showInput'] = false
				response.data[i]['visible'] = true
			}
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			setFilterData(filterDataCopy)
		}

		setDropdownIsOpen(false)
	}

	async function openHistroyModal(item) {
		var urlParams = ""

		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search


		const response = await PGET(`/services/web/api/revaluation-history-pageList/${item.productId + urlParams}`, {}, { loader: true })
		setProducts(response.data)
		setPagination2({ ...pagination2, 'url': `/services/web/api/revaluation-history-pageList/${item.productId + urlParams}`, 'response': response })
		setShowHistoryModal(true)
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(newPageData) {
		for (let i = 0; i < newPageData.length; i++) {
			newPageData[i]['showInput'] = false
			newPageData[i]['visible'] = true
		}
		setData(newPageData)
	}

	async function paginate2(newPageData) {
		setProducts(newPageData)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
		searchWithFilter({ passedFilterData: { 'posId': response[0]['id'] } })
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('REVALUATION')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex flex-wrap mb-3">
						<div className="d-flex">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button type="button" className="btn btn-primary" onClick={() => searchWithFilter()}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table mb-0">
							<thead>
								<tr>
									<th>№</th>
									{(!reduxSettings.tableRevaluation || reduxSettings.tableRevaluation?.pos) &&
										<th>{t('POS')}</th>
									}
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									{(!reduxSettings.tableRevaluation || reduxSettings.tableRevaluation?.artikul) &&
										<th className="text-center">{t('ARTIKUL')}</th>
									}
									<th className="text-center">{t('ADMISSION_PRICE')}</th>
									<th className="text-center">{t('WHOLESALE_PRICE')}</th>
									{(!reduxSettings.tableRevaluation || reduxSettings.tableRevaluation?.bankPrice) &&
										<th className="text-center">{t('BANK_PRICE')}</th>
									}
									<th className="text-center">{t('UNIT_PRICE')}</th>
									<th className="text-center">{t('IN_THE_WAREHOUSE')}</th>
									<th className="text-center table-control">
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										{t('ACTION')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}</td>
										{(!reduxSettings.tableRevaluation || reduxSettings.tableRevaluation?.pos) &&
											<td title={item.posName}>{item.posName}</td>
										}
										<td>{item.productName}</td>
										<td className="text-center">{item.barcode}</td>
										{(!reduxSettings.tableRevaluation || reduxSettings.tableRevaluation?.artikul) &&
											<td className="text-center">{item.artikul}</td>
										}
										<td className="text-center text-nowrap">
											<span>{formatMoney(item.price)}</span>
										</td>
										<td className="text-center text-nowrap">
											{!item.showInput ?
												<span>{formatMoney(item.wholesalePrice)}</span>
												:
												<input type="number" step="0.01" min="0" className={"auto-width-input " + ((Number(item.price) > Number(item.wholesalePrice)) && "required-border")}
													name="wholesalePrice" value={item.wholesalePrice} onChange={(e) => handleInputChange(e, index)} />
											}
										</td>
										{(!reduxSettings.tableRevaluation || reduxSettings.tableRevaluation?.bankPrice) &&
											<td className="text-center text-nowrap">
												{!item.showInput ?
													<span>{formatMoney(item.bankPrice)}</span>
													:
													<input type="number" step="0.01" min="0" className={"auto-width-input " + ((Number(item.price) > Number(item.bankPrice)) && "required-border")}
														name="bankPrice" value={item.bankPrice} onChange={(e) => handleInputChange(e, index)} />
												}
											</td>
										}
										<td className="text-center text-nowrap">
											{!item.showInput ?
												<span>{formatMoney(item.salePrice)}</span>
												:
												<input type="number" step="0.01" min="0" className={"auto-width-input " + ((Number(item.wholesalePrice) > Number(item.salePrice)) && "required-border")}
													name="salePrice" value={item.salePrice} onChange={(e) => handleInputChange(e, index)} />
											}
										</td>
										<td className="text-center">{item.balance} {item.uomName}</td>
										<td className="d-flex justify-content-center">
											{(!item.showInput && item.visible) &&
												<div className="d-flex">
													<div className="table-action-button table-action-primary-button me-2" onClick={() => selectedItemEdit(index)}>
														<i className="uil-edit-alt"></i>
													</div>
													<div className="table-action-button table-action-primary-button" onClick={() => openHistroyModal(item)}>
														<i className="uil-list-ul"></i>
													</div>
												</div>
											}
											{item.showInput &&
												<>
													<div className="table-action-button table-action-primary-button me-2" onClick={() => confirmModalShow(index)}>
														<i className="uil-check"></i>
													</div>
													<div className="table-action-button table-action-danger-button" onClick={() => cancelSelectedItemEdit(index)}>
														<i className="uil-times"></i>
													</div>
												</>
											}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL CONFIRM */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('CONFIRM')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th></th>
									<th className="text-center">До</th>
									<th className="text-center">После</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="fw-700">{t('ADMISSION_PRICE')}</td>
									<td className="text-center">{dataDuplicate.price}</td>
									<td className="text-center">{changedItem.price}</td>
								</tr>
								<tr>
									<td className="fw-700">{t('WHOLESALE_PRICE')}</td>
									<td className="text-center">{dataDuplicate.wholesalePrice}</td>
									<td className="text-center">{changedItem.wholesalePrice}</td>
								</tr>
								<tr>
									<td className="fw-700">{t('UNIT_PRICE')}</td>
									<td className="text-center">{dataDuplicate.salePrice}</td>
									<td className="text-center">{changedItem.salePrice}</td>
								</tr>
							</tbody>
						</table>
					</div>

					<input type="text" placeholder={t('NOTE')} className="form-control"
						onChange={(e) => setChangedItem({ ...changedItem, 'note': e.target.value })} />
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setShowModal(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => update()}>{t('SAVE')}</button>
					</div>
				</Modal.Footer>
			</Modal>
			{/* MODAL CONFIRM */}

			{/* HISTORY CONFIRM */}
			<Modal show={showHistoryModal} animation={false} centered
				size="xl" backdrop="static" onHide={() => setShowHistoryModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('HISTORY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>№ {t('POS')}</th>
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th>{t('BARCODE')}</th>
									<th className="text-center">{t('ADMISSION_PRICE')}</th>
									<th className="text-center">{t('WHOLESALE_PRICE')}</th>
									<th className="text-center">{t('UNIT_PRICE')}</th>
									<th className="text-center">{t('NOTE')}</th>
									<th className="text-center">{t('LOGIN')}</th>
									<th className="text-center">{t('DATE')}</th>
								</tr>
							</thead>
							<tbody>
								{products?.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.posName}</td>
										<td>{item.productName}</td>
										<td>{item.productBarcode}</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="text-nowrap me-2">{formatMoney(item.oldPrice)}</div>
												<div className="me-2">
													<i className="uil uil-arrow-right"></i>
												</div>
												<span className="text-nowrap">{formatMoney(item.newPrice)}</span>
											</div>
										</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="text-nowrap me-2">{formatMoney(item.oldWholesalePrice)}</div>
												<div className="me-2">
													<i className="uil uil-arrow-right"></i>
												</div>
												<span className="text-nowrap">{formatMoney(item.newWholesalePrice)}</span>
											</div>
										</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="text-nowrap me-2">{formatMoney(item.oldSalePrice)}</div>
												<div className="me-2">
													<i className="uil uil-arrow-right"></i>
												</div>
												<span className="text-nowrap">{formatMoney(item.newSalePrice)}</span>
											</div>
										</td>
										<td className="text-center">
											{item.note}
										</td>
										<td className="text-center">
											{item.createdBy}
										</td>
										<td className="text-center">
											{formatDateWithTime(item.createdDate)}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination2.response &&
						<FilterPagination
							pagination={pagination2}
							paginate={paginate2}>
						</FilterPagination>
					}
				</Modal.Body>
			</Modal>
			{/* HISTORY CONFIRM */}

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableRevaluation'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Revaluation

