import React, { useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'

import { setNavTab } from 'store/layout/actions'
//Import Scrollbar
import SimpleBar from "simplebar-react"
import { Nav } from 'react-bootstrap'
import { exportMenu } from './menu'
import { exportMenu2 } from './menu2'
import { exportMenuMerchendiser } from './menuMerchendiser'
import { exportMenuMerchendiser2 } from './menuMerchendiser2'

function SidebarContent() {
	const role = localStorage.getItem('role');

	const { t } = useTranslation()
	const ref = useRef();
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const navTabKey = useSelector(state => state.Layout.navTabKey)

	const [menu] = useState((role && role === "ROLE_MERCHANDISER") ? [...exportMenuMerchendiser, ...exportMenuMerchendiser2] : [...exportMenu, ...exportMenu2])
	const [activeMenu, setActiveMenu] = useState(exportMenu[0])
	// const [menu2] = useState((role && role === "ROLE_MERCHANDISER") ? exportMenuMerchendiser2 : exportMenu2)
	//const [navTabKey, setNavTabKey] = useState('link_1')

	function setMenuActive() {
		console.log(!(navTabKey >= 0));
		console.log(navTabKey);
		if (!(navTabKey >= 0)) {
			return;
		}
		var copyActiveMenu = { ...menu[navTabKey] }
		if (copyActiveMenu?.children?.length) {
			for (let i = 0; i < copyActiveMenu.children.length; i++) {
				if (location.pathname.includes(copyActiveMenu.children[i].url)) {
					copyActiveMenu.children[i].active = true;
				} else {
					copyActiveMenu.children[i].active = false;
				}
			}
			setActiveMenu(copyActiveMenu)
		}
	}

	function setNav(index) {
		if (index >= 0) {
			setActiveMenu(menu[index])
			dispatch(setNavTab(index))
		}
		if (index === 100) {
			navigate('/settings')
		}

		// if (link === 'link_3') {
		// 	dispatch(setNavTab('link_1'))
		// 	navigate('/settings')
		// } else {
		// 	dispatch(setNavTab(link))
		// }
	}

	useEffect(() => {
		setMenuActive()
	}, [location]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<SimpleBar style={{ maxHeight: "100%" }} ref={ref} className="sidebar-menu-scroll">
				<Nav className="nav-mini" variant="tabs" activeKey={navTabKey}>
					{menu.map((item, index) => (
						<Nav.Item onClick={() => setNav(index)} key={index}>
							<Nav.Link eventKey={index} tabIndex="-1">
								<span className="fw-bold"><i className={item.icon}></i></span>
							</Nav.Link>
						</Nav.Item>
					))
					}
					<Nav.Item onClick={() => setNav(100)}>
						<Nav.Link eventKey={100} tabIndex="-1">
							<i className="uil-setting"></i>
						</Nav.Link>
					</Nav.Item>
				</Nav>

				<div id="sidebar-menu">
					<ul className="metismenu list-unstyled" id="side-menu">
						{activeMenu?.children.map((item, index) => (
							<li className={item.active ? "mm-active" : ""} key={index}>
								<Link to={item.url} className={"waves-effect " + (item.active ? "active" : "")} tabIndex="-1">
									<i className={item.icon}></i>
									<span>{t(item.i18n)}</span>
								</Link>
							</li>
						))
						}
					</ul>
				</div>

				{/* {navTabKey === "link_2" &&
					<div id="sidebar-menu">
						<ul className="metismenu list-unstyled" id="side-menu">
							{menu2.map((item, index) => (
								<li className={item.active ? "mm-active" : ""} key={index}>
									<Link to={item.url} className={"waves-effect " + (item.active ? "active" : "")} tabIndex="-1">
										<i className={item.icon}></i>
										<span>{t(item.i18n)}</span>
									</Link>
								</li>
							))
							}
						</ul>
					</div>
				} */}

			</SimpleBar>
		</>
	)
}

export default SidebarContent