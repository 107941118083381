import React, { useEffect, useState } from "react"
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { GET } from 'helpers/api'
import { formatMoney, formatDate } from 'helpers/helpers'

function Preview() {
	const { t } = useTranslation()
	const { id } = useParams();

	const [data, setData] = useState({})

	async function getData() {
		const response = await GET("/services/web/api/inventory/" + id)
		if (response) {
			setData(response)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('INVENTORY')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('PREVIEW')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h4 className="fw-600 mb-3">{t('INVENTORY')} {formatDate(data.beginDate)}</h4>
					<div className="d-flex flex-wrap">
						<div className="me-3">
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('POS')}:</div>
								<div className="w-100 text-end text-nowrap">{data.posName}</div>
							</div>
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('NOTE')}:</div>
								<div className="w-100 text-end text-nowrap">{data.note}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped">
							<thead>
								<tr>
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									<th className="text-center">{t('UNIT')}.</th>
									<th className="text-center">{t("PRICE")}</th>
									<th className="text-center">{t('EXPECTED_BALANCE')}</th>
									<th className="text-center">{t('COUNTED')}</th>
									<th className="text-center">{t('DIVERGENCE')}</th>
									<th className="text-end">{t('COST_DIFFERENCE')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.productList?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}. {item.productName}</td>
										<td className="text-center">{item.barcode}</td>
										<td className="text-center">{item.uomName}</td>
										<td className="text-center">{formatMoney(item.price)}</td>
										<td className="text-center">{item.balance}</td>
										<td className="text-center">{item.actualBalance}</td>
										<td className="text-center">{item.divergence}</td>
										<td className="text-end">{formatMoney(item.differenceAmount)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<div className="w-100 d-flex justify-content-end">
						<div className="flex-column w-25">
							<h5 className="d-flex justify-content-between">
								<strong className="me-2">{t('LACK_OF')}:</strong>{formatMoney(data.shortage)} {data.currencyName}
							</h5>
							<h5 className="d-flex justify-content-between">
								<strong className="me-2">{t('SURPLUS')}:</strong>{formatMoney(data.surplus)} {data.currencyName}
							</h5>
						</div>
					</div>

					<div className="d-flex justify-content-end mt-5">
						<Link className="btn btn-warning btn-rounded btn-wide" to="/inventory">
							{t('CANCEL')}
						</Link>
					</div>
				</div>
			</div>
		</>
	)
}

export default Preview
