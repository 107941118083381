import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'

function Pagination({ pageCount, paginate, filters, reduxPage }) {
	const [currentPage, setCurrentPage] = useState(0)

	async function getCurrentPage(pageNumber, bool = true) {
		if (bool) {
			setCurrentPage(pageNumber.selected)
			paginate(pageNumber)
		}
	}

	function forceToPage(page) {
		setCurrentPage(page)
		getCurrentPage({ 'selected': page }, false)
	}

	useEffect(() => {
		if (reduxPage) {
			setCurrentPage(reduxPage)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (filters?.page >= 0) {
			forceToPage(filters?.page)
		}
	}, [filters?.posId]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{pageCount > 1 &&
				<ReactPaginate
					forcePage={currentPage}
					breakLabel={'...'}
					breakClassName={'break-me'}
					pageCount={pageCount}
					marginPagesDisplayed={3}
					pageRangeDisplayed={3}
					onPageChange={getCurrentPage}
					containerClassName={'pagination'}
					activeClassName={'active'}
					pageClassName={'page-item'}
				/>
			}
		</>
	)
}

export default Pagination
