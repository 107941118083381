import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import XLSX from 'xlsx';

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET, GET } from 'helpers/api'
import { formatMoney, formatDateWithTime } from 'helpers/helpers'

function Index() {
	const URL = "/services/wms/api/inventory-pageList"
	const { t } = useTranslation()

	const settings = useSelector(state => state.settings)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function exportToExcel(item) {
		const response = await GET('/services/wms/api/inventory/' + item.id)
		console.log(response);

		var excelHeaders = []
		excelHeaders.push([t('DATE'), response['beginDate']])
		excelHeaders.push([t('WAREHOUSE'), response['wmsName']])
		excelHeaders.push([t('NOTE'), response['note']])
		excelHeaders.push([t('LACK_OF'), formatMoney(response['shortage'])])
		excelHeaders.push([t('SURPLUS'), formatMoney(response['surplus'])])
		excelHeaders.push([
			t('NAME_OF_PRODUCT'), t('BARCODE'), t('UNIT'), t('PRICE'), t('EXPECTED_BALANCE'),
			t('COUNTED'), t('DIVERGENCE'), t('COST_DIFFERENCE')
		])

		for (let i = 0; i < response.productList.length; i++) {
			var excelItems = []
			excelItems.push(response.productList[i]['productName'])
			excelItems.push(response.productList[i]['barcode'])
			excelItems.push(response.productList[i].uomName)
			excelItems.push(formatMoney(response.productList[i]['price']))
			excelItems.push(response.productList[i]['balance'])
			excelItems.push(response.productList[i]['actualBalance'])
			excelItems.push(response.productList[i]['divergence'])
			excelItems.push(formatMoney(response.productList[i]['differenceAmount']))
			excelHeaders.push(excelItems)
		}
		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('INVENTORY') + ".xlsx");
	}

	async function getData() {
		const response = await PGET(URL)
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('INVENTORY')}
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/wms/inventory/create">
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>№</th>
									<th>{t('WAREHOUSE')}</th>
									<th className="text-center">№ {t('DOCUMENT')}</th>
									<th className="text-center">{t('BEGIN_DATE')}</th>
									<th className="text-center">{t('END_DATE')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}</td>
										<td>{item.wmsName}</td>
										<td className="text-center">{item.inventoryNumber}</td>
										<td className="text-center">{formatDateWithTime(item.beginDate)}</td>
										<td className="text-center">{formatDateWithTime(item.endDate)}</td>
										<td className="text-center">
											{!item.completed ?
												<span className="bg-secondary bg-border text-white">{t('EXPECTED')}</span>
												:
												<span className="bg-success bg-border text-white">{t('CLOSED')}</span>
											}
										</td>
										<td className="d-flex justify-content-center">
											{item.completed ?
												<Link to={'/wms/inventory/preview/' + item.id} className="me-2">
													<div className="table-action-button table-action-primary-button">
														<i className="uil-eye"></i>
													</div>
												</Link>
												:
												<>
													<Link to={'/wms/inventory/update-socket/' + item.id} className="me-2">
														<div className="table-action-button table-action-primary-button">
															<i className="uil-clouds"></i>
														</div>
													</Link>
													<Link to={'/wms/inventory/update/' + item.id} className="me-2">
														<div className="table-action-button table-action-primary-button">
															<i className="uil-edit-alt"></i>
														</div>
													</Link>
												</>

											}
											<div className="table-action-button table-action-success-button" onClick={() => exportToExcel(item)}>
												<i className="uil-file-alt"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
