export let exportMenuMerchendiser2 = [
	{
		icon: "uil-book-open",
		active: false,
		children: [
			{
				url: "/products",
				i18n: "PRODUCTS",
				icon: "uil-shopping-cart",
				active: false,
			},
			{
				url: "/suppliers",
				i18n: "SUPPLIERS",
				icon: "uil-truck-loading",
				active: false,
			},
			{
				url: "/currency",
				i18n: "CURRENCY",
				icon: "uil-money-bill",
				active: false,
			}
		]
	}
]