import React from 'react'
import Barcode from 'react-barcode'
//import { useSelector } from 'react-redux'
import { todayDate, formatMoney } from 'helpers/helpers'

function PriceTag60x40({
	data, selectedPriceTag, selectPriceTag,
	printProducts, printRef, cashbox
}) {

	function getSeperatedPrice(price, index = 0) {
		const myArray = price.split(" ");
		if (index === 0) {
			return myArray[0]
		}
		if (index === 1) {
			if (myArray.length === 2) {
				return myArray[1]
			} else if (myArray.length === 3) {
				return myArray[1] + ' ' + myArray[2]
			} else {
				return 0
			}
		}
	}

	return (
		<>
			<div className="d-flex flex-wrap">
				{/* PriceTag_8 ( 60x40 ) */}
				<div className={"price_tag_body_60x40 price-tag-border mb-3 me-3 " + (selectedPriceTag.priceTag60x40_1 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag60x40_1')}>
					<div className="d-flex justify-content-between price_tag_body_60x40_1_barcode">
						<Barcode value={data.barcode} width={1} height={16} background="transparent" textMargin={0}
							fontOptions="bold" fontSize={10} />
						<div className="price-tag-price-wrapper">
							<div>
								<b className="fz20">
									{formatMoney(data.actionPrice, 0)}
								</b>
							</div>
							<hr className="price-tag-throw-line" />
						</div>
					</div>
					<div className="d-flex justify-content-center price_tag_body_60x40_1_price">
						<div className="d-flex fz30">
							<div className="vertical-center">
								<span className="price_tag_body_60x40_1_price_left">{getSeperatedPrice(formatMoney(data.salePrice, 0), 0)}</span>
							</div>
							<div className="price_tag_body_60x40_1_price_right">
								<div>{cashbox.defaultCurrency}</div>
								<div className="text-center">
									{getSeperatedPrice(formatMoney(data.salePrice, 0), 1)}
								</div>
							</div>
						</div>
					</div>
					<div className="vertical-center">
						<div className="price_tag_body_60x40_1_name">{data.productName}</div>
					</div>
					<div className="fz10 date">{todayDate()}</div>
				</div>
				{/* PriceTag_8 ( 60x40 ) */}
			</div>

			<div className="d-none">
				{selectedPriceTag.priceTag60x40_1 &&
					<div ref={printRef}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_60x40 page-breaker" key={index}
								onClick={() => selectPriceTag('priceTag60x40_1')}>
								<div className="d-flex justify-content-between barcode">
									<Barcode value={product.barcode} width={1} height={16} background="transparent" textMargin={0}
										fontOptions="bold" fontSize={10} />
									<div className="price-tag-price-wrapper">
										<div>
											<b className="fz22">
												{formatMoney(product.actionPrice, 0)}
											</b>
										</div>
										<hr className="price-tag-throw-line" />
									</div>
								</div>
								<div className="d-flex justify-content-center price_tag_body_60x40_1_price">
									<div className="d-flex fz30">
										<div className="vertical-center">
											<span className="price_tag_body_60x40_1_price_left">{getSeperatedPrice(formatMoney(product.salePrice, 0), 0)}</span>
										</div>
										<div className="price_tag_body_60x40_1_price_right">
											<div>{cashbox.defaultCurrency}</div>
											<div className="text-center">
												{getSeperatedPrice(formatMoney(product.salePrice, 0), 1)}
											</div>
										</div>
									</div>
								</div>
								<div className="vertical-center">
									<div className="price_tag_body_60x40_1_name">{product.productName}</div>
								</div>
								<div className="fz10 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}
			</div>
		</>
	)
}

export default PriceTag60x40