import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Modal } from 'react-bootstrap'
import { DebounceInput } from 'react-debounce-input'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'
import TableControl from 'pages/parts/TableControl'

import { PGET, GET, PUT, POST } from 'helpers/api'
import { findIdFromArray, formatDateWithTime } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const settings = useSelector(state => state.settings)
	const reduxSettings = useSelector(state => state.settings)

	const [pageCount, setPageCount] = useState(0)
	const [showModal, setShowModal] = useState(false)
	const [modalTableControl, setModalTableControl] = useState(false)
	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [products, setProducts] = useState([])
	const [posId, setPosId] = useState(0)
	const [dataDuplicate, setDataDuplicate] = useState({})

	async function handleSelect(option) {
		//setPagination({ ...pagination, 'url': '/services/web/api/scales-pageList?posId=' + option.id })
		const response = await PGET(`/services/web/api/balance-notification-pageList/${option.id}?size=500`, {}, { loader: true })
		getProducts(option.id)
		if (response) {
			for (let i = 0; i < response.data.length; i++) {
				response.data[i]['showInput'] = false
				response.data[i]['visible'] = true
			}
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
			setPosId(option.id)
		}
	}

	async function update(index) {
		var dataCopy = [...data]

		await PUT("/services/web/api/balance-notification", dataCopy[index], { loader: true })

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i].visible = true
			dataCopy[i].showInput = false
		}
		setData(dataCopy)
	}

	async function create() {
		var productsCopy = [...products]
		var temporaryArray = []
		for (let i = 0; i < productsCopy.length; i++) {
			if (productsCopy[i]['minBalance'] > 0) {
				productsCopy[i]['posId'] = posId
				temporaryArray.push(productsCopy[i])
			}
		}
		await POST("/services/web/api/balance-notification", temporaryArray, { loader: true })
		setShowModal(false)
		getData()
	}

	async function activate(bool, index) {
		var dataCopy = [...data]
		dataCopy[index]['sms'] = bool
		await PUT("/services/web/api/balance-notification-activate/" + dataCopy[index]['id'], { 'boolean': bool }, { loader: true })
		setData(dataCopy)
	}

	function selectedItemEdit(index) {
		var dataCopy = [...data]
		setDataDuplicate({ ...dataCopy[index] })

		dataCopy[index].showInput = true
		for (let i = 0; i < dataCopy.length; i++) {
			if (!dataCopy[i].showInput) {
				dataCopy[i].visible = false
			}
		}
	}

	function cancelSelectedItemEdit(index) {
		var dataCopy = [...data]
		dataCopy[index] = { ...dataDuplicate }
		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i].visible = true
			dataCopy[i].showInput = false
		}
		setData(dataCopy)
	}

	function handleInputChange(e, index) {
		var dataCopy = [...data]
		dataCopy[index][e.target.name] = e.target.value
		setData(dataCopy)
	}

	function setMinBalance(e, index) {
		var productsCopy = [...products]
		productsCopy[index][e.target.name] = e.target.value
		setProducts(productsCopy)
	}

	// async function deleteItem(index, id) {
	// 	await DELETE("/services/web/api/balance-notification/" + id)
	// 	var dataCopy = [...data]
	// 	dataCopy.splice(index, 1)
	// 	setData(dataCopy)
	// }

	function sortBy(key, orderBy) {
		var productsCopy = [...products]
		console.log(productsCopy);
		if (key === 'balance' && orderBy === 'asc') {
			productsCopy.sort((a, b) => parseFloat(a.actualBalance) - parseFloat(b.actualBalance));
		}
		if (key === 'balance' && orderBy === 'desc') {
			productsCopy.sort((a, b) => parseFloat(b.actualBalance) - parseFloat(a.actualBalance));
		}
		if (key === 'name' && orderBy === 'asc') {
			productsCopy.sort((a, b) => a.productName.localeCompare(b.productName))
		}
		if (key === 'name' && orderBy === 'desc') {
			productsCopy.sort((a, b) => b.productName.localeCompare(a.productName))
		}

		setProducts(productsCopy)
	}

	async function getData() {
		const temporaryPoses = await GET("/services/web/api/pos-helper", {}, { loader: true })
		setPoses(temporaryPoses)

		var temporaryPosId;
		if (reduxSettings.posId && findIdFromArray(temporaryPoses, reduxSettings.posId)) {
			setPosId(reduxSettings.posId)
			temporaryPosId = reduxSettings.posId
		} else {
			setPosId(temporaryPoses[0]['id'])
			temporaryPosId = temporaryPoses[0]['id']
		}

		const response = await PGET(`/services/web/api/balance-notification-pageList/${temporaryPosId}?size=500`)
		if (response) {
			for (let i = 0; i < response.data.length; i++) {
				response.data[i]['showInput'] = false
				response.data[i]['visible'] = true
			}

			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}

		getProducts(temporaryPosId)
	}

	async function paginate(data) {
		const response = await PGET("/services/web/api/balance-notification-pageList/" + posId, { page: data.selected, size: settings.size })
		if (response) {
			for (let i = 0; i < response.data.length; i++) {
				response.data[i]['showInput'] = false
				response.data[i]['visible'] = true
			}
			
			setData(response.data);
		}
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	async function requestToServer(value) {
		var data;

		if (value.length === 0) {
			getData()
		}

		if (value.length > 1) {
			data = await GET(`/services/web/api/balance-notification-list?posId=${posId}&barcode=false&name=` + value)
			setProducts(data);
		}
	};

	async function getProducts(id) {
		const response = await GET("/services/web/api/balance-notification-list?posId=" + id)
		if (response) {
			setProducts(response)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('NOTIFICATIONS')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => setShowModal(true)}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<Search url={"/services/web/api/balance-notification-pageList/" + posId} search={search} getData={getData}></Search>
						<div className="w-25">
							<Select
								options={poses}
								value={poses.find(option => option.id === posId)}
								onChange={handleSelect}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('NAME_OF_PRODUCT')}</th>
									{(!reduxSettings.tableNotfications || reduxSettings.tableNotfications?.pos) &&
										<th>{t('POS')}</th>
									}
									<th className="text-center">{t('BARCODE')}</th>
									{(!reduxSettings.tableNotfications || reduxSettings.tableNotfications?.artikul) &&
										<th className="text-center">{t('ARTIKUL')}</th>
									}
									<th className="text-center">{t('MIN_BALANCE')}</th>
									<th className="text-center">{t('IN_THE_WAREHOUSE')}</th>
									<th className="text-center">{t('ACTIVATE')}</th>
									<th className="text-center">{t('SENDED')} SMS</th>
									<th className="text-center">{t('DATE')}</th>
									<th className="text-center table-control">
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										{t('ACTION')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}. {item.productName}</td>
										{(!reduxSettings.tableNotfications || reduxSettings.tableNotfications?.pos) &&
											<td>{item.posName}</td>
										}
										<td className="text-center">{item.productBarcode}</td>
										{(!reduxSettings.tableNotfications || reduxSettings.tableNotfications?.artikul) &&
											<td className="text-center">{item.productArtikul}</td>
										}
										<td className="text-center">
											{!item.showInput ?
												<span>{item.minBalance}</span>
												:
												<input type="number" step="0.01" min="0" className="auto-width-input"
													name="minBalance" value={item.minBalance} onChange={(e) => handleInputChange(e, index)} />
											}
										</td>
										<td className="text-center">
											{item.actualBalance < item.minBalance ?
												<span className="bg-danger bg-border text-white">
													{item.actualBalance}
												</span>
												:
												<span>{item.actualBalance}</span>
											}
										</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="form-check form-switch form-switch-lg">
													<input type="checkbox" className="form-check-input"
														checked={item.sms}
														onChange={(e) => activate(e.target.checked, index)} />
												</div>
											</div>
										</td>
										<td className="text-center">{item.smsSent}</td>
										<td className="text-center">{formatDateWithTime(item.lastSentDate)}</td>
										<td className="d-flex justify-content-center">
											{(!item.showInput && item.visible) &&
												<div className="table-action-button table-action-primary-button" onClick={() => selectedItemEdit(index)}>
													<i className="uil-edit-alt"></i>
												</div>
											}
											{item.showInput &&
												<>
													<div className="table-action-button table-action-primary-button me-2" onClick={() => update(index)}>
														<i className="uil-check"></i>
													</div>
													<div className="table-action-button table-action-danger-button" onClick={() => cancelSelectedItemEdit(index)}>
														<i className="uil-times"></i>
													</div>
												</>
											}
											{/* <div className="table-action-button table-action-danger-button" onClick={() => deleteItem(item.id)}>
												<i className="uil-times-circle"></i>
											</div> */}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered size="lg" dialogClassName="update-modal-width" backdrop="static" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						<div className="d-flex align-items-center w-100">
							<div className="me-2">
								<div className="position-relative me-2">
									<i className="uil-search input-search-icon"></i>
									<DebounceInput
										type="text"
										className="form-control"
										placeholder={t('SEARCH') + '...'}
										minLength={2}
										debounceTimeout={1000}
										onChange={(e) => requestToServer(e.target.value)}
										onKeyUp={(e) => {
											if (e.keyCode === 13) {
												requestToServer(e.target.value)
											}
										}}
									/>
								</div>
							</div>
							<div className="d-flex">
								<div className="me-2">{t('SORT_BY')}</div>
								<button className="btn btn-sm btn-success me-2" onClick={() => sortBy('balance', 'asc')}>[0-9]</button>
								<button className="btn btn-sm btn-success me-2" onClick={() => sortBy('balance', 'desc')}>[9-0]</button>
								<button className="btn btn-sm btn-success me-2" onClick={() => sortBy('name', 'asc')}>[A-Z]</button>
								<button className="btn btn-sm btn-success me-2" onClick={() => sortBy('name', 'desc')}>[Z-A]</button>
							</div>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive inventory-modal-height">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									<th className="text-center">{t('BALANCE')}</th>
									<th className="text-center">{t('MIN_BALANCE')}</th>
								</tr>
							</thead>
							<tbody>
								{products.map((item, index) => (
									<tr key={index}>
										<td>
											<label className="form-check-label vertical-center text-truncate"
												htmlFor={'control' + index} style={{ 'width': '300px' }}>
												{index + 1}. {item.productName}
											</label>
										</td>
										<td className="text-center">{item.productBarcode}</td>
										<td className="text-center">{item.actualBalance} {item.uomName}</td>
										<td className="text-center">
											<input type="number" className="auto-width-input" autoComplete="off"
												name="minBalance"
												value={item.minBalance} onChange={(e) => setMinBalance(e, index)}
											/>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setShowModal(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => create()}>{t('SAVE')}</button>
					</div>
				</Modal.Footer>
			</Modal>
			{/* MODAL */}

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableNotfications'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Index
