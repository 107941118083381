import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print';
import XLSX from 'xlsx'

import { formatMoney, formatDateWithTime, regexPhoneNumber } from 'helpers/helpers'
import logo from "assets/images/logo.svg";

function Rightbar({ showRightBar, setShowRightBar, selectedItem }) {
	const { t } = useTranslation()
	const printRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	var totalDiscountAmount = 0;

	for (let i = 0; i < selectedItem?.itemsList?.length; i++) {
		totalDiscountAmount += selectedItem.itemsList[i].discountAmount
	}

	function selectedItemToExcel() {
		var excelHeaders = []
		excelHeaders.push([`${t('CASHIER')}`, '', '', '', selectedItem.cashierName])
		excelHeaders.push([`${t('CHEQUE')} №`, '', '', '', selectedItem.chequeNumber])
		excelHeaders.push([`${t('DATE')} №`, '', '', '', formatDateWithTime(selectedItem.chequeDate)])
		excelHeaders.push([t('NAME_OF_PRODUCT'), t('BARCODE'), t('QUANTITY'), t('PRICE'), t('TOTAL')])
		
		for (let i = 0; i < selectedItem.itemsList.length; i++) {
			var excelItems = []
			excelItems.push(`${i + 1}. ${selectedItem.itemsList[i].productName}`)
			excelItems.push(selectedItem.itemsList[i].barcode)
			excelItems.push(selectedItem.itemsList[i].quantity)
			excelItems.push(selectedItem.itemsList[i].salePrice)
			excelItems.push(selectedItem.itemsList[i].totalPrice)
			excelHeaders.push(excelItems)
		}
		excelHeaders.push([t('SALE_AMOUNT'), '', '', '', selectedItem.totalPrice])
		if (selectedItem.discount > 0) {
			excelHeaders.push([t('DISCOUNT'), '', '', '', totalDiscountAmount])
		} else {
			excelHeaders.push([t('DISCOUNT'), '', '', '', 0])
		}
		excelHeaders.push([t('TO_PAY'), '', '', '', selectedItem.totalPrice - totalDiscountAmount])
		excelHeaders.push([t('PAID'), '', '', '', selectedItem.paid])
		if (selectedItem.vat > 0) {
			excelHeaders.push([t('VAT') + selectedItem.vat + '%', '', '', '', selectedItem.totalVatAmount])
		} else {
			excelHeaders.push([t('VAT') + ' 0%', '', '', '', formatMoney(0)])
		}
		if (selectedItem.label) {
			excelHeaders.push([t('MARKING'), '', '', '', selectedItem.label ? t('YES') : t('NO')])
		}
		if (selectedItem.transactionsList.length > 0) {
			for (let i = 0; i < selectedItem.transactionsList.length; i++) {
				excelHeaders.push([selectedItem.transactionsList[i]['paymentTypeName'], '', '', '', selectedItem.transactionsList[i]['amountIn']])
			}
		}
		excelHeaders.push([t('CHANGE2'), '', '', '', selectedItem.change])
		if (selectedItem.clientAmount) {
			excelHeaders.push([t('AMOUNT_OF_DEBT'), '', '', '', selectedItem.clientAmount])
		}
		if (selectedItem.clientAmount > 0) {
			excelHeaders.push([t('DEBTOR'), '', '', '', selectedItem.clientName])
		}
		if ((Number(selectedItem.clientAmount) === 0 && selectedItem.clientName)) {
			excelHeaders.push([t('CLIENT'), '', '', '', selectedItem.clientName])
		}

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		// ws['!cols'] = fitToColumn(excelHeaders);
		// function fitToColumn(arrayOfArray) {
		// 	return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i].toString().length)) }));
		// }
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('CHEQUES') + ".xlsx");
	}

	return (
		<>
			{showRightBar &&
				<>
					<div className="right-sidebar-background" onClick={() => setShowRightBar(!showRightBar)}></div>
					<div className="right-sidebar">

						<div className="d-flex justify-content-center position-relative mt-3 mb-1">
							<div className="rightbar-close-icon" onClick={() => setShowRightBar(!showRightBar)}>
								<i className="uil uil-times"></i>
							</div>
							<div className="d-flex flex-column">
								<div className="d-flex justify-content-center mb-1">
									<img src={logo} width={120} alt="logo" />
								</div>
								<h5 className="text-center fw-700 mb-1">{t('DUPLICATE')}</h5>
								<h5 className="text-center fw-700 mb-1">{selectedItem.posName}</h5>
								<h6 className="text-center fw-600 mb-1">{t('PHONE')}: {selectedItem.posPhone}</h6>
								<h6 className="text-center fw-500 mb-1">{t('ADDRESS')}: {selectedItem.posAddress}</h6>
							</div>
						</div>

						<div className="cheque-block-1 cheque-border-bottom mb-2">
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('CASHIER')}</p>
								<p>{selectedItem.cashierName}</p>
							</div>
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('CHEQUE')} № </p>
								<p>{selectedItem.chequeNumber}</p>
							</div>
							{selectedItem.tin &&
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('TIN')}</p>
									<p>{selectedItem.tin}</p>
								</div>
							}
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('DATE')}</p>
								<p>{formatDateWithTime(selectedItem.chequeDate)}</p>
							</div>
						</div>

						<div className="cheque-block-2 cheque-border-bottom px-2">
							<table className="custom-cheque-table w-100 fz-14">
								<thead>
									<tr>
										<th className="w-50">№ {t('PRODUCT')}</th>
										<th>{t('QUANTITY')}</th>
										<th className="text-end">{t('PRICE')}</th>
									</tr>
								</thead>
								<tbody>
									{selectedItem?.itemsList?.map((item, index) => (
										<tr key={index}>
											{/* column 1 */}
											<td className="d-flex" style={{ 'whiteSpace': 'break-spaces' }}>
												{(item.returned === 0 || item.returned === 1) &&
													<span>{index + 1}. {item.productName}</span>
												}
												{item.returned === 2 &&
													<span><del>{index + 1} {item.productName}</del></span>
												}
											</td>
											{/* column 1 */}

											{/* column 2 */}
											{(item.vat !== 0 && item.returned === 0) &&
												<td className="text-left" style={{ 'verticalAlign': 'top' }}>
													{item.returned === 0 &&
														<span className="text-nowrap" >{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</span>
													}
													{item.returned === 1 &&
														<span>
															<div className="text-nowrap">{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</div>
															<div className="text-nowrap"><del>{item.returnedQuantity === 0 ? formatMoney(item.quantity) : formatMoney(item.returnedQuantity)} X {formatMoney(item.salePrice)}</del></div>
														</span>
													}
													{item.returned === 2 &&
														<div className="text-nowrap"><del>{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</del></div>
													}
												</td>
											}
											{(item.vat === 0 && item.returned === 0) &&
												<td className="text-left" style={{ 'verticalAlign': 'top' }}>
													{item.returned === 0 &&
														<span className="text-nowrap">{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</span>
													}
													{item.returned === 1 &&
														<span>
															<div className="text-nowrap">{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</div>
															<div className="text-nowrap"><del>{item.returnedQuantity === 0 ? formatMoney(item.quantity) : formatMoney(item.returnedQuantity)} X {formatMoney(item.salePrice)}</del></div>
														</span>
													}
													{item.returned === 2 &&
														<div className="text-nowrap"><del>{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</del></div>
													}
												</td>
											}

											{/* column 2 */}

											{/* column 3 */}
											{(item.vat !== 0 && item.returned === 0) &&
												<td className="text-end">
													{formatMoney(item.totalPrice)} <br />

													{(item.discount !== 0) &&
														<div className="text-end text-nowrap">
															{t('DISCOUNT')} 
															{item.discountAmount > 0 &&
																<span className="ms-1">{formatMoney(item.discountAmount)}</span>
															}
														</div>
													}	
													<div className="text-end text-nowrap">
														{t('VAT')} {formatMoney(item.vat)}%
														{item.vatAmount > 0 &&
															<span className="ms-1">{formatMoney(item.vatAmount)}</span>
														}
													</div>
												</td>
											}
											{(item.vat === 0 && item.returned === 0) &&
												<td className="text-end align-top text-nowrap">
													{formatMoney(item.totalPrice)}
													<br />
													{(item.discount !== 0) &&
														<div className="text-end text-nowrap">
															{t('DISCOUNT')} 
															{item.discountAmount > 0 &&
																<span className="ms-1">{formatMoney(item.discountAmount)}</span>
															}
														</div>
													}	
													<span>{t('VAT_TEXT')}</span>
												</td>
											}
											{(item.vat !== 0 && item.returned !== 0) &&
												<td colSpan="2">
													<div className="d-flex justify-content-between">
														<div className={"text-nowrap " + (item.returned !== 0 && 'del')}>
															{item.quantity} X {formatMoney(item.salePrice)}
															{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
																<br />
															}
															{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
																<span>
																	{item.returnedQuantity} X {formatMoney(item.salePrice)}
																</span>
															}
														</div>
														<div className={"text-end text-nowrap " + (item.returned !== 0 && 'del')}>
															{formatMoney(item.totalPrice)}
															{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
																<>
																	<br />
																	<span>
																		{formatMoney(item.returnedPrice)}
																	</span>
																</>
															}
															{(item.discount !== 0) &&
																<div className="text-end text-nowrap">
																	{t('DISCOUNT')} 
																	{item.discountAmount > 0 &&
																		<span className="ms-1">{formatMoney(item.discountAmount)}</span>
																	}
																</div>
															}	
														</div>
													</div>
													<div className={"text-end text-nowrap " + (item.returned !== 0 && 'del')}>
														{t('VAT')} {formatMoney(item.vat)}%
														{item.vatAmount > 0 &&
															<span className="ms-1">{formatMoney(item.vatAmount)}</span>
														}
													</div>
													<div className="text-end">
														{item.returned === 1 &&
															<div className="text-uppercase text-nowrap">{t('PRODUCT_PARTIALLY_RETURNED')}</div>
														}
														{item.returned === 2 &&
															<div className="text-uppercase text-nowrap">{t('PRODUCT_RETURNED')}</div>
														}
													</div>
												</td>
											}
											{(item.vat === 0 && item.returned !== 0) &&
												<td colSpan="2">
													<div className="d-flex justify-content-between">
														<div className={"text-nowrap " + (item.returned !== 0 && 'del')}>
															{item.quantity} X {formatMoney(item.salePrice)}
															{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
																<br />
															}
															{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
																<span>
																	{item.returnedQuantity} X {formatMoney(item.salePrice)}
																</span>
															}
														</div>
														<div className={"text-end text-nowrap " + (item.returned !== 0 && 'del')}>
															{formatMoney(item.totalPrice)}
															{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
																<>
																	<br />
																	<span>
																		{formatMoney(item.returnedPrice)}
																	</span>
																</>
															}
															{(item.discount !== 0) &&
																<div className="text-end text-nowrap">
																	{t('DISCOUNT')} 
																	{item.discountAmount > 0 &&
																		<span className="ms-1">{formatMoney(item.discountAmount)}</span>
																	}
																</div>
															}	
														</div>
													</div>
													<div className={"text-end text-nowrap " + (item.returned !== 0 && 'del')}>
														{t('VAT')} {item.vat}%
														{item.vatAmount > 0 &&
															<span className="ms-1">{formatMoney(item.vatAmount)}</span>
														}
													</div>
													<div className="text-end">
														{item.returned === 1 &&
															<div className="text-uppercase text-nowrap">{t('PRODUCT_PARTIALLY_RETURNED')}</div>
														}
														{item.returned === 2 &&
															<div className="text-uppercase text-nowrap">{t('PRODUCT_RETURNED')}</div>
														}
													</div>
												</td>
											}
											{/* column 3 */}
										</tr>
									))
									}
								</tbody>
							</table>
						</div>

						<div className="cheque-block-3 cheque-border-bottom mb-2">
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('SALE_AMOUNT')}</p>
								<p>{formatMoney(selectedItem.totalPrice)}</p>
							</div>
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('DISCOUNT')}</p>
								{selectedItem.discount > 0 ?
									<p>{formatMoney(totalDiscountAmount)}</p>
									:
									<p>{formatMoney(0)}</p>
								}
							</div>
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600 fz-30">{t('TO_PAY')}</p>
								<p className="fw-600 fz-30">{formatMoney(selectedItem.totalPrice - totalDiscountAmount)}</p>
							</div>
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('PAID')}</p>
								<p className="fw-600">{formatMoney(selectedItem.paid)}</p>
							</div>
							{selectedItem.vat &&
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('VAT')} {selectedItem.vat}%</p>
									<p className="fw-600">{formatMoney(selectedItem.totalVatAmount)}</p>
								</div>
							}
							{selectedItem.saleCurrencyId > 0 &&
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('CURRENCY')}</p>
									{selectedItem.saleCurrencyId === 1 &&
										<p className="text-capitalize">{t('SUM')}</p>
									}
									{selectedItem.saleCurrencyId === 2 &&
										<p>USD</p>
									}
								</div>
							}
							{selectedItem.label &&
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('MARKING')}</p>
									<p className="fw-600">{selectedItem.label ? t('YES') : t('NO')}</p>
								</div>
							}
							{selectedItem.transactionsList?.map((item, index) => (
								<div className="d-flex justify-content-between px-2" key={index}>
									<p className="fw-600">{item.paymentTypeName}</p>
									<p>{formatMoney(item.amountIn)}</p>
								</div>
							))
							}
							{selectedItem.loyaltyClientName &&
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('CLIENT')}</p>
									<p>{selectedItem.loyaltyClientName}</p>
								</div>
							}
							{selectedItem.loyaltyBonus > 0 &&
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">uGet {t('BONUS')}</p>
									<p>{selectedItem.loyaltyBonus}</p>
								</div>
							}
							{selectedItem.loyaltyLogin &&
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">uGet {t('PHONE')}</p>
									<p>+{regexPhoneNumber(selectedItem.loyaltyLogin)}</p>
								</div>
							}
							<div className="d-flex justify-content-between px-2">
								<p>{t('CHANGE2')}</p>
								<p>{formatMoney(selectedItem.change)}</p>
							</div>
							{selectedItem.clientAmount > 0 &&
								<div className="d-flex justify-content-between px-2">
									<p>{t('AMOUNT_OF_DEBT')}</p>
									<p>{formatMoney(selectedItem.clientAmount)}</p>
								</div>
							}
							{Number(selectedItem.clientAmount) > 0 &&
								<div className="d-flex justify-content-between px-2">
									<p>{t('DEBTOR')}</p>
									{selectedItem.clientName &&
										<p>{selectedItem.clientName}</p>
									}
								</div>
							}
							{(Number(selectedItem.clientAmount) === 0 && selectedItem.clientName) &&
								<div className="d-flex justify-content-between px-2">
									<p>{t('CLIENT')}</p>
									{selectedItem.clientName &&
										<p>{selectedItem.clientName}</p>
									}
								</div>
							}
							{selectedItem.organizationAmount > 0 &&
								<div className="d-flex justify-content-between px-2">
									<p>{t('AMOUNT_OF_DEBT')}</p>
									<p>{formatMoney(selectedItem.organizationAmount)}</p>
								</div>
							}
							{Number(selectedItem.organizationAmount) > 0 &&
								<div className="d-flex justify-content-between px-2">
									<p>{t('DEBTOR')}</p>
									{selectedItem.organizationName &&
										<p>{selectedItem.organizationName}</p>
									}
								</div>
							}
							{(Number(selectedItem.organizationAmount) === 0 && selectedItem.organizationName) &&
								<div className="d-flex justify-content-between px-2">
									<p>{t('CLIENT')}</p>
									{selectedItem.organizationName &&
										<p>{selectedItem.organizationName}</p>
									}
								</div>
							}
						</div>

						<div className="d-flex justify-content-between px-2 mb-3">
							<span>{t('ACTION')}</span>
							<div className="d-flex">
								<div className="table-action-button table-action-primary-button me-2" onClick={selectedItemToExcel}>
									<i className="uil uil-file-alt"></i>
								</div>
								<div className="table-action-button table-action-primary-button" onClick={handlePrint}>
									<i className="uil uil-print"></i>
								</div>
							</div>
						</div>
					</div>

					<div style={{ display: "none" }}>
						<div id="cheque" ref={printRef} className="color-dark-theme">
							<div className="d-flex justify-content-center mt-3 mb-1">
								<div className="d-flex flex-column">
									<div className="d-flex justify-content-center mb-1">
										<img src={logo} width={120} alt="logo" />
									</div>
									<h5 className="text-center fw-700 mb-1">{t('DUPLICATE')}</h5>
									<h5 className="text-center fw-700 mb-1">{selectedItem.posName}</h5>
									<h6 className="text-center fw-600 mb-1">{t('PHONE')}: {selectedItem.posPhone}</h6>
									<h6 className="text-center fw-500 mb-1">{t('ADDRESS')}: {selectedItem.posAddress}</h6>
								</div>
							</div>

							<div className="cheque-block-1 cheque-border-bottom mb-2 fz-12">
								<div className="d-flex justify-content-between px-2">
									<p>{t('CASHIER')}</p>
									<p>{selectedItem.cashierName}</p>
								</div>
								<div className="d-flex justify-content-between px-2">
									<p>{t('CHEQUE')} № </p>
									<p>{selectedItem.chequeNumber}</p>
								</div>
								{selectedItem.tin &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('TIN')}</p>
										<p>{selectedItem.tin}</p>
									</div>
								}
								<div className="d-flex justify-content-between px-2">
									<p>{t('DATE')}</p>
									<p>{formatDateWithTime(selectedItem.chequeDate)}</p>
								</div>
							</div>

							<div className="cheque-block-2 cheque-border-bottom px-2">
								<table className="custom-cheque-table w-100 color-dark-theme">
									<thead>
										<tr>
											<th className="fz-12 w-50">№ {t('PRODUCT')}</th>
											<th className="fz-12">{t('QUANTITY')}</th>
											<th className="fz-12 text-end">{t('PRICE')}</th>
										</tr>
									</thead>
									<tbody className="fz-12">
										{selectedItem?.itemsList?.map((item, index) => (
											<tr key={index}>
												{/* column 1 */}
												<td className="d-flex" style={{ 'whiteSpace': 'break-spaces' }}>
													{(item.returned === 0 || item.returned === 1) &&
														<span>{index + 1}. {item.productName}</span>
													}
													{item.returned === 2 &&
														<span><del>{index + 1} {item.productName}</del></span>
													}
												</td>
												{/* column 1 */}

												{/* column 2 */}
												{(item.vat !== 0 && item.returned === 0) &&
													<td className="text-left" style={{ 'verticalAlign': 'top' }}>
														{item.returned === 0 &&
															<span className="text-nowrap" >{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</span>
														}
														{item.returned === 1 &&
															<span>
																<div className="text-nowrap">{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</div>
																<div className="text-nowrap"><del>{item.returnedQuantity === 0 ? formatMoney(item.quantity) : formatMoney(item.returnedQuantity)} X {formatMoney(item.salePrice)}</del></div>
															</span>
														}
														{item.returned === 2 &&
															<div className="text-nowrap"><del>{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</del></div>
														}
													</td>
												}
												{(item.vat === 0 && item.returned === 0) &&
													<td className="text-left" style={{ 'verticalAlign': 'top' }}>
														{item.returned === 0 &&
															<span className="text-nowrap">{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</span>
														}
														{item.returned === 1 &&
															<span>
																<div className="text-nowrap">{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</div>
																<div className="text-nowrap"><del>{item.returnedQuantity === 0 ? formatMoney(item.quantity) : formatMoney(item.returnedQuantity)} X {formatMoney(item.salePrice)}</del></div>
															</span>
														}
														{item.returned === 2 &&
															<div className="text-nowrap"><del>{formatMoney(item.quantity)} X {formatMoney(item.salePrice)}</del></div>
														}
													</td>
												}
												{/* column 2 */}

												{/* column 3 */}
												{(item.vat !== 0 && item.returned === 0) &&
													<td className="text-end text-nowrap">
														{formatMoney(item.totalPrice)} <br />
														{(item.discount !== 0) &&
															<div className="text-end text-nowrap">
																{t('DISCOUNT')} 
																{item.discountAmount > 0 &&
																	<span className="ms-1">{formatMoney(item.discountAmount)}</span>
																}
															</div>
														}	
														<div className="text-end text-nowrap">
															{t('VAT')} {formatMoney(item.vat)}%
															{item.vatAmount > 0 &&
																<span className="ms-1">{formatMoney(item.vatAmount)}</span>
															}
														</div>
													</td>
												}
												{(item.vat === 0 && item.returned === 0) &&
													<td className="text-end text-nowrap">
														{formatMoney(item.totalPrice)}
														{(item.discount !== 0) &&
															<div className="text-end text-nowrap">
																{t('DISCOUNT')} 
																{item.discountAmount > 0 &&
																	<span className="ms-1">{formatMoney(item.discountAmount)}</span>
																}
															</div>
														}	
													</td>
												}
												{(item.vat !== 0 && item.returned !== 0) &&
													<td colSpan="2">
														<div className="d-flex justify-content-between">
															<div className={"text-nowrap " + (item.returned !== 0 && 'del')}>
																{item.quantity} X {formatMoney(item.salePrice)}
																{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
																	<br />
																}
																{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
																	<span className="text-nowrap">
																		{item.returnedQuantity} X {formatMoney(item.salePrice)}
																	</span>
																}
															</div>
															<div className={"text-end text-nowrap " + (item.returned !== 0 && 'del')}>
																{formatMoney(item.totalPrice)}
																{(item.returnedQuantity && item.returnedQuantity !== item.quantity) &&
																	<>
																		<br />
																		<span>
																			{formatMoney(item.returnedPrice)}
																		</span>
																	</>
																}
																{(item.discount !== 0) &&
																	<div className="text-end text-nowrap">
																		{t('DISCOUNT')} 
																		{item.discountAmount > 0 &&
																			<span className="ms-1">{formatMoney(item.discountAmount)}</span>
																		}
																	</div>
																}	
															</div>
														</div>
														<div className={"text-end text-nowrap " + (item.returned !== 0 && 'del')}>
															{t('VAT')} {formatMoney(item.vat)}%
															{item.vatAmount > 0 &&
																<span className="ms-1">{formatMoney(item.vatAmount)}</span>
															}
														</div>
														<div className="text-end">
															{item.returned === 1 &&
																<div className="text-uppercase text-nowrap">{t('PRODUCT_PARTIALLY_RETURNED')}</div>
															}
															{item.returned === 2 &&
																<div className="text-uppercase text-nowrap">{t('PRODUCT_RETURNED')}</div>
															}
														</div>
													</td>
												}
												{(item.vat === 0 && item.returned !== 0) &&
													<td colSpan="2">
														<div className="d-flex justify-content-between">
															<div className="text-nowrap"><del>{item.quantity} X {formatMoney(item.salePrice)}</del></div>
															<div className={"text-end text-nowrap " + (item.returned !== 0 && 'del')}>
																{formatMoney(item.totalPrice)}
																{(item.discount !== 0) &&
																	<div className="text-end text-nowrap">
																		{t('DISCOUNT')} 
																		{item.discountAmount > 0 &&
																			<span className="ms-1">{formatMoney(item.discountAmount)}</span>
																		}
																	</div>
																}	
															</div>
														</div>
														<div className={"text-end text-nowrap " + (item.returned !== 0 && 'del')}>
															{t('VAT')} {item.vat}%
															{item.vatAmount > 0 &&
																<span className="ms-1">{formatMoney(item.vatAmount)}</span>
															}
														</div>
														<div className="text-end">
															{item.returned === 1 &&
																<div className="text-uppercase text-nowrap">{t('PRODUCT_PARTIALLY_RETURNED')}</div>
															}
															{item.returned === 2 &&
																<div className="text-uppercase text-nowrap">{t('PRODUCT_RETURNED')}</div>
															}
														</div>
													</td>
												}
												{/* column 3 */}
											</tr>
										))
										}
									</tbody>
								</table>
							</div>

							<div className="cheque-block-3 cheque-border-bottom mb-2 fz-12">
								<div className="d-flex justify-content-between px-2">
									<p>{t('SALE_AMOUNT')}</p>
									<p>{formatMoney(selectedItem.totalPrice)}</p>
								</div>
								<div className="d-flex justify-content-between px-2">
									<p>{t('DISCOUNT')}</p>
									{selectedItem.discount > 0 ?
										<p>{formatMoney(totalDiscountAmount)}</p>
										:
										<p>{formatMoney(0)}</p>
									}
								</div>
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600 fz-18">{t('TO_PAY')}</p>
									<p className="fw-600 fz-18">{formatMoney(selectedItem.totalPrice - totalDiscountAmount)}</p>
								</div>
								<div className="d-flex justify-content-between px-2">
									<p>{t('PAID')}</p>
									<p>{formatMoney(selectedItem.paid)}</p>
								</div>
								{selectedItem.vat &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('VAT')} {selectedItem.vat}%</p>
										<p>{formatMoney(selectedItem.totalVatAmount)}</p>
									</div>
								}
								{selectedItem.saleCurrencyId > 0 &&
									<div className="d-flex justify-content-between px-2">
										<p className="fw-600">{t('CURRENCY')}</p>
										{selectedItem.saleCurrencyId === 1 &&
											<p className="text-capitalize">{t('SUM')}</p>
										}
										{selectedItem.saleCurrencyId === 2 &&
											<p>USD</p>
										}
									</div>
								}
								{selectedItem.label &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('MARKING')}</p>
										<p>{selectedItem.label ? t('YES') : t('NO')}</p>
									</div>
								}
								{selectedItem.transactionsList?.map((item, index) => (
									<div className="d-flex justify-content-between px-2" key={index}>
										<p>{item.paymentTypeName}</p>
										<p>{formatMoney(item.amountIn)}</p>
									</div>
								))
								}
								{selectedItem.loyaltyClientName &&
									<div className="d-flex justify-content-between px-2">
										<p className="fw-600">{t('CLIENT')}</p>
										<p>{selectedItem.loyaltyClientName}</p>
									</div>
								}
								{selectedItem.loyaltyBonus > 0 &&
									<div className="d-flex justify-content-between px-2">
										<p className="fw-600">uGet{t('BONUS')}</p>
										<p>{selectedItem.loyaltyBonus}</p>
									</div>
								}
								{selectedItem.loyaltyLogin &&
									<div className="d-flex justify-content-between px-2">
										<p className="fw-600">uGet {t('PHONE')}</p>
										<p>+{regexPhoneNumber(selectedItem.loyaltyLogin)}</p>
									</div>
								}
								<div className="d-flex justify-content-between px-2">
									<p>{t('CHANGE2')}</p>
									<p>{formatMoney(selectedItem.change)}</p>
								</div>
								{selectedItem.clientAmount > 0 &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('AMOUNT_OF_DEBT')}</p>
										<p>{formatMoney(selectedItem.clientAmount)}</p>
									</div>
								}
								{Number(selectedItem.clientAmount) > 0 &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('DEBTOR')}</p>
										{selectedItem.clientName &&
											<p>{selectedItem.clientName}</p>
										}
									</div>
								}
								{(Number(selectedItem.clientAmount) === 0 && selectedItem.clientName) &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('CLIENT')}</p>
										{selectedItem.clientName &&
											<p>{selectedItem.clientName}</p>
										}
									</div>
								}
								{selectedItem.organizationAmount > 0 &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('AMOUNT_OF_DEBT')}</p>
										<p>{formatMoney(selectedItem.organizationAmount)}</p>
									</div>
								}
								{Number(selectedItem.organizationAmount) > 0 &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('DEBTOR')}</p>
										{selectedItem.organizationName &&
											<p>{selectedItem.organizationName}</p>
										}
									</div>
								}
								{(Number(selectedItem.organizationAmount) === 0 && selectedItem.organizationName) &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('CLIENT')}</p>
										{selectedItem.organizationName &&
											<p>{selectedItem.organizationName}</p>
										}
									</div>
								}
							</div>
						</div>
					</div>
				</>
			}
		</>
	)
}

export default Rightbar
