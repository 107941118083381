import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactApexChart from "react-apexcharts";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { GET } from 'helpers/api'
import { formatMoney } from 'helpers/helpers';

function Index() {
	const { t } = useTranslation()

	const [topData, setTopData] = useState({})
	const [grossIncome, setGrossIncome] = useState({
		seriesCopy: [{
			name: 'Валовый прошлый',
			data: []
		}, {
			name: 'Валовый текущий',
			data: []
		}],
		series: [{
			name: 'Валовый прошлый',
			data: []
		}, {
			name: 'Валовый текущий',
			data: []
		}],
		options: {
			colors: ['#C5D1FF', '#00E396'],
			chart: {
				height: 350,
				type: 'bar',
				toolbar: {
					show: false
				},
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			yaxis: {
				labels: {
					formatter: function (value) {
						return formatMoney(value, 0);
					}
				},
			},
			xaxis: {
				type: 'datetime',
				labels: {
					format: 'dd',
				},
				tooltip: {
					enabled: false
				},
				categories: []
			},
			tooltip: {
				x: {
					format: 'dd.MM.yyyy'
				},
			},
		},
	}
	)
	const [show] = useState(!false)
	const [loader, setLoader] = useState(0)
	const [grossIncomeFilter, setGrossIncomeFilter] = useState({
		incomeCurrent: true,
		incomeLast: true,
		show: true,
	})

	const report = {
		id: 1,
		icon: "mdi mdi-arrow-up-bold",
		title: "Total Revenue",
		value: 34152,
		prefix: "$",
		suffix: "",
		badgeValue: "2.65%",
		decimal: 0,
		charttype: "bar",
		chartheight: 40,
		chartwidth: 70,
		color: "success",
		desc: "since last week",
		series: [{
			data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
		}],
		options: {
			fill: {
				colors: ['#5b73e8']
			},
			chart: {
				width: 70,
				sparkline: {
					enabled: !0
				}
			},
			plotOptions: {
				bar: {
					columnWidth: '50%'
				}
			},
			labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			xaxis: {
				crosshairs: {
					width: 1
				},
			},
			tooltip: {
				fixed: {
					enabled: !1
				},
				x: {
					show: !1
				},
				y: {
					title: {
						formatter: function (seriesName) {
							return '';
						}
					}
				},
				marker: {
					show: !1
				}
			}
		},
	}

	async function getData() {
		setLoader(1)
		const responseTopData = await GET('/services/web/api/dashboard-one')
		const responseGrossIncome = await GET('/services/web/api/dashboard-income')

		if (responseTopData && responseGrossIncome) {
			setTopData(responseTopData)

			var grossIncomeCopy = { ...grossIncome }

			var now = new Date();
			var days = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();

			for (let i = 0; i < days; i++) {
				grossIncomeCopy.series[1]['data'].push(responseGrossIncome.incomeCurrent[i]['incomeSum'])
				grossIncomeCopy.series[0]['data'].push(responseGrossIncome.incomeLast[i]['incomeSum'])

				grossIncomeCopy.seriesCopy[1]['data'].push(responseGrossIncome.incomeCurrent[i]['incomeSum'])
				grossIncomeCopy.seriesCopy[0]['data'].push(responseGrossIncome.incomeLast[i]['incomeSum'])

				grossIncomeCopy.options.xaxis.categories.push(responseGrossIncome.incomeCurrent[i]['incomeDate'])
			}

			setGrossIncome(grossIncomeCopy)
			setLoader(2)
		} else {
			setLoader(0)
		}
	}

	function handleGrossIncomeFilter(type, bool) {
		if (type === 1) {
			calculateGrossIncome(type, bool)
		}
		if (type === 2) {
			calculateGrossIncome(type, bool)
		}
	}

	function calculateGrossIncome(type, bool) {
		if (type === 1) {
			setGrossIncomeFilter({ ...grossIncomeFilter, 'show': false, 'incomeCurrent': bool })
		}
		if (type === 2) {
			setGrossIncomeFilter({ ...grossIncomeFilter, 'show': false, 'incomeLast': bool })
		}

		var grossIncomeCopy = { ...grossIncome }
		if (type === 1 && bool) {
			grossIncomeCopy.series[1]['data'] = grossIncomeCopy.seriesCopy[1]['data']
			setGrossIncome(grossIncomeCopy)
		}
		if (type === 1 && !bool) {
			grossIncomeCopy.series[1]['data'] = []
			setGrossIncome(grossIncomeCopy)
		}

		if (type === 2 && bool) {
			grossIncomeCopy.series[0]['data'] = grossIncomeCopy.seriesCopy[0]['data']
			setGrossIncome(grossIncomeCopy)
		}
		if (type === 2 && !bool) {
			grossIncomeCopy.series[0]['data'] = []
			setGrossIncome(grossIncomeCopy)
		}

		setTimeout(() => {
			if (type === 1) {
				setGrossIncomeFilter({ ...grossIncomeFilter, 'show': true, 'incomeCurrent': bool })
			}
			if (type === 2) {
				setGrossIncomeFilter({ ...grossIncomeFilter, 'show': true, 'incomeLast': bool })
			}
		}, 500);
	}

	useEffect(() => {
		// getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('HOME')}
				</h4>
			</div>

			{show &&
				<>
					{loader === 2 &&
						<>
							<div className="row mb-3">
								<div className="col-md-3">
									<div className="card h-100">
										<div className="card-body">
											<div className="d-flex justify-content-between">
												<div className="mt-1">
													<h4 className="text-nowrap"><b>{formatMoney(topData.grossIncome)}</b></h4>
													<p className="text-muted mb-0">{t('GROSS_INCOME')}</p>
												</div>
												<div className="mt-2">
													<ReactApexChart
														options={report.options}
														series={report.series}
														type={report.charttype}
														height={report.chartheight}
														width={report.chartwidth}
													/>
												</div>
											</div>
											{/* <p className="text-muted mt-3 mb-0">
										<span className="text-success me-1">
											<i className="uil-arrow-up me-1"></i>2.65%
										</span> с прошлой недели
									</p> */}
										</div>
									</div>
								</div>

								<div className="col-md-3">
									<div className="card h-100">
										<div className="card-body">
											<div className="d-flex justify-content-between">
												<div className="mt-1">
													<h4 className="text-nowrap"><b>{formatMoney(topData.frozenAmount)}</b></h4>
													<p className="text-muted mb-0">{t('FROZEN_FUNDS')}</p>
												</div>
												<div className="mt-2">
													<ReactApexChart
														options={report.options}
														series={report.series}
														type={report.charttype}
														height={report.chartheight}
														width={report.chartwidth}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-3">
									<div className="card h-100">
										<div className="card-body">
											<div className="d-flex justify-content-between">
												<div className="mt-1">
													<h4 className="text-nowrap"><b>{formatMoney(topData.avgChequeAmount)}</b></h4>
													<p className="text-muted mb-0">{t('AVERAGE_CHECK')}</p>
												</div>
												<div className="mt-2">
													<ReactApexChart
														options={report.options}
														series={report.series}
														type={report.charttype}
														height={report.chartheight}
														width={report.chartwidth}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-3">
									<div className="card h-100">
										<div className="card-body">
											<div className="">
												<div className="d-flex justify-content-between mt-1">
													<h4 className="text-nowrap me-2"><b>{formatMoney(topData.cashAmount)}</b></h4>
													<div className="d-flex">
														<p className="text-primary mb-0 me-2 vertical-center">{t('CASH')}</p>
														<i className="uil-money-bill vertical-center text-primary fz18"></i>
													</div>
												</div>
												<div className="d-flex justify-content-between mt-1">
													<h4 className="text-nowrap me-2"><b>{formatMoney(topData.cardAmount)}</b></h4>
													<div className="d-flex">
														<p className="text-success mb-0 me-2 vertical-center">{t('TERMINAL')}</p>
														<i className="uil-credit-card vertical-center text-success fz18"></i>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<div className="card">
										<div className="card-body">
											<div className="d-flex justify-content-between">
												<h4>{t('GROSS_INCOME')}</h4>
												<div className="d-flex">
													<div
														className="dshbutton me-3"
														onClick={() => handleGrossIncomeFilter(1, !grossIncomeFilter.incomeCurrent)}
														style={{ 'opacity': grossIncomeFilter.incomeCurrent ? "1" : "0.5" }}>
														<span className="current"></span>
														<p>Текущий месяц</p>
													</div>
													<div
														className="dshbutton me-3"
														onClick={() => handleGrossIncomeFilter(2, !grossIncomeFilter.incomeLast)}
														style={{ 'opacity': grossIncomeFilter.incomeLast ? "1" : "0.5" }}>
														<span className="last"></span>
														<p>Прошлый месяц</p>
													</div>
												</div>
											</div>
											<hr />
											{grossIncomeFilter.show ?
												<ReactApexChart options={grossIncome.options} series={grossIncome.series} type="bar" height={300} />
												:
												<Skeleton height={300} />
											}
										</div>
									</div>
								</div>
							</div>
						</>
					}
					{loader === 1 &&
						<>
							<div className="row mb-3">
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<Skeleton height={300} />
								</div>
							</div>
						</>
					}
					{loader === 0 &&
						<div className="d-flex justify-content-center mt-5" onClick={() => getData()}>
							<button className="btn-rounded btn btn-lg btn-primary">
								{t('GENERATE_REPORT')}
							</button>
						</div>
					}
				</>
			}
		</>
	)
}

export default Index
