export function SET_DOCUMENT_IN_PRODUCT_LIST(payload) {
	return {
		type: 'SET_DOCUMENT_IN_PRODUCT_LIST',
		payload: payload,
	};
}

export function SET_DOCUMENT_IN_CREATE_DATA(payload) {
	return {
		type: 'SET_DOCUMENT_IN_CREATE_DATA',
		payload: payload,
	};
}

export function SET_DOCUMENT_WMS_IN_CREATE_DATA(payload) {
	return {
		type: 'SET_DOCUMENT_WMS_IN_CREATE_DATA',
		payload: payload,
	};
}

export function SET_INVENTORY_CREATE_DATA(payload) {
	return {
		type: 'SET_INVENTORY_CREATE_DATA',
		payload: payload,
	};
}

export function SET_TRANSFER_POS(payload) {
	return {
		type: 'SET_TRANSFER_POS',
		payload: payload,
	};
}

export function SET_TRANSFER_WMS(payload) {
	return {
		type: 'SET_TRANSFER_WMS',
		payload: payload,
	};
}

export function SET_DOCUMENT_IN_CREATE_PRODUCT(payload) {
	return {
		type: 'SET_DOCUMENT_IN_CREATE_PRODUCT',
		payload: payload,
	};
}